import { RouteRecordRaw } from "vue-router";

import LandingPage from "@/views/LandingPage.vue";
import FlightSearchResult from "@/views/FlightSearchResults.vue";
import { ROLES_AND_PERMISSION_ON_ROUTES } from "@/ag-portal-common/configs/rolesAndPermissionsMappingOnRoutes";
import { PATH } from "@/ag-portal-common/constants/path";

const routes: Array<RouteRecordRaw> = [
  {
    path: PATH.ROOT,
    name: "landingPage",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.FLIGHTS.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.FLIGHTS.PERMISSIONS,
    },
    component: LandingPage,
  },
  {
    path: PATH.FLIGHTS_SEARCH_RESULT,
    name: "flightSearchResult",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.FLIGHTS.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.FLIGHTS.PERMISSIONS,
    },
    component: FlightSearchResult,
  },
];

export default routes;
