import { RouteRecordRaw } from "vue-router";

import { ROLES_AND_PERMISSION_ON_ROUTES } from "@/ag-portal-common/configs/rolesAndPermissionsMappingOnRoutes";
import { PATH } from "@/ag-portal-common/constants/path";
import FlightBookingForm from "@/views/FlightBookingForm.vue";
import FlightBookingDetails from "@/views/FlightBookingDetails.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: PATH.FLIGHTS_BOOKING_FORM,
    name: "bookingForm",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.FLIGHTS_BOOKING_FORM.ROLES,
      permissions:
        ROLES_AND_PERMISSION_ON_ROUTES.FLIGHTS_BOOKING_FORM.PERMISSIONS,
    },
    component: FlightBookingForm,
  },
  {
    path: PATH.FLIGHTS_BOOKING_DETAILS,
    name: "flightBookingDetails",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.FLIGHTS_BOOKING_FORM.ROLES,
      permissions:
        ROLES_AND_PERMISSION_ON_ROUTES.FLIGHTS_BOOKING_FORM.PERMISSIONS,
    },
    component: FlightBookingDetails,
  },
];

export default routes;
