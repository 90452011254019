<template>
  <Layout>
    <template #content>
      <ag-loader v-if="isLoading" />
      <template v-else>
        <AgCard test-id="">
          <ag-row>
            <ag-column xs="12" sm="12" md="8" lg="9">
              <AgSelectedFlightInfo
                :date="formatTripDates"
                :adult="
                  $store.state.flightBookingModule.flightDetails?.adult_count
                "
                :children="
                  $store.state.flightBookingModule.flightDetails?.child_count
                "
                :infant="
                  $store.state.flightBookingModule.flightDetails?.infant_count
                "
              >
                <template #destination>
                  {{ formatLegsInfo }}
                </template>
              </AgSelectedFlightInfo>
            </ag-column>
            <ag-column xs="12" sm="12" md="3">
              <AgSelectedFlightInfo>
                <template #pnrInfo>
                  <AgDiv class="booking_id_wrap"
                    >Booking Confirmation
                    <span>{{ renderBookingId }}</span></AgDiv
                  >
                </template>
              </AgSelectedFlightInfo>
            </ag-column>
          </ag-row>
        </AgCard>
        <!-- Contact Details -->
        <AgRow test-id="">
          <AgColumn test-id="" md="8" lg="9">
            <!-- Lead Contact Box -->
            <AgCard test-id="">
              <AgIconInfoBar
                icon="contactPhoneIcon"
                title="Contact Details"
                test-id=""
              />
              <ag-accordion
                class="flight_accordion_wrap margin_bottom_0"
                :panel-value="[0]"
              >
                <ag-accordion-panel>
                  <template #flightInfo>
                    <ag-heading
                      variant="h3"
                      title="Lead Traveler"
                      class="margin_bottom_0"
                    ></ag-heading>
                  </template>
                  <template #tabSection>
                    <AgRow test-id="" class="padding_top_20">
                      <AgColumn test-id="" md="7" lg="7">
                        <AgRow test-id="">
                          <AgColumn test-id="" md="12" lg="9">
                            <AgPhoneField
                              test-id=""
                              default-country-code="PK"
                              info-message="Please input the traveler’s
                          number here to receive flight updates"
                              @input="handleMobileFieldChange"
                              :error="renderMobileNumberError"
                            />
                          </AgColumn>
                        </AgRow>
                      </AgColumn>
                      <AgColumn test-id="" md="5" lg="5">
                        <!-- Side Box -->
                        <AgTravelDetail
                          test-id=""
                          :items="{
                            'Mobile Number':
                              $store.state.flightBookingModule.mobileNumber,
                          }"
                        >
                          <template #headingArea>
                            <AgHeading
                              variant="h2"
                              title="Contact Details"
                              class="margin_bottom_5"
                            />
                          </template>
                        </AgTravelDetail>
                      </AgColumn>
                    </AgRow>
                  </template>
                </ag-accordion-panel>
              </ag-accordion>
            </AgCard>

            <TavelerCard
              v-for="(traveler, index) in travelers"
              :traveler="traveler"
              :errors="errors"
              :index="index"
              :key="index"
            />

            <AgCard test-id="">
              <AgDiv test-id="" class="text-right">
                <AGButton
                  test-id=""
                  type="button"
                  @click="handleConfirmBooking"
                  :is-loading="isConfirmBookingLoading"
                  >Confirm Booking</AGButton
                >
              </AgDiv>
            </AgCard>
          </AgColumn>
          <AgColumn test-id="" md="4" lg="3">
            <ag-card>
              <PriceSummary />
            </ag-card>
            <ag-heading
              variant="h2"
              title="Trip Summary"
              class="margin_bottom_10"
            />
            <TripSummary :legs="getAllLegs" />
          </AgColumn>
        </AgRow>
      </template>
    </template>
  </Layout>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ValidationError } from "yup";
import { format } from "date-fns";
import _ from "lodash";

import { bookingFormValidationSchema } from "@/ag-flight-components/validations/bookingFormValidation";
import PriceSummary from "@/ag-flight-components/components/FlightBooking/PriceSummary.vue";
import TripSummary from "@/ag-flight-components/components/FlightBooking/TripSummary.vue";
import TavelerCard from "@/ag-flight-components/components/FlightBooking/TravelerCard.vue";
import { FlightDetailsType, FlightLeg } from "@/ag-flight-components/types";
import { ROUTE_TYPE } from "@/ag-flight-components/enums/route_type";
import { TimeUtility } from "@/ag-flight-components/utils/TimeUtility";
import { formatStringToRoutePath } from "@/ag-portal-common/utils/helpers";
import {
  IConfirmBooking,
  Traveler,
} from "@/ag-flight-components/types/FlightBookingForm";
import { PATH } from "@/ag-portal-common/constants/path";
import { yupValidationErrorAsSchema } from "@/ag-portal-common/utils/helpers";
import analyticsService from "@/services/analytics.service";
import { FLIGHT_ANALYTICS_EVENTS } from "@/constants/analyticsEvents";
import {
  FORMAT_DD_MMM_YYYY_WITH_DASH,
  FORMAT_MMM_D,
  FORMAT_YYY_MM_DD,
} from "@/ag-portal-common/constants/dateTimeFormats";
import Layout from "@/components/Layout.vue";

export default defineComponent({
  name: "FlightBookingForm",
  data(): { errors: any; flightDetails: FlightDetailsType | null } {
    return {
      flightDetails: null,
      errors: {},
    };
  },
  components: {
    TavelerCard,
    PriceSummary,
    TripSummary,
    Layout,
  },
  computed: {
    renderBookingId() {
      return this.$route.params.id;
    },
    renderMobileNumberError(): string {
      return this.errors?.mobile_number as string;
    },
    isLoading(): boolean {
      return this.$store.getters.isFlightBookingLoading;
    },
    isConfirmBookingLoading() {
      return this.$store.getters.isFlightConfirmBookingLoading;
    },
    travelers() {
      return this.$store.getters.flightTravelers;
    },
    formatLegsInfo() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails;
      const legs: FlightLeg[] = flightDetails?.flight_legs || [];
      const results = legs
        .map((item: FlightLeg, idx: number) => {
          return `${item.origin.iata_code} - ${item.destination.iata_code}${
            idx === legs.length - 1 ? "" : ","
          }`;
        })
        .join(" ");
      return results;
    },
    formatTripDates() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails;
      const legs: FlightLeg[] = flightDetails?.flight_legs || [];
      const firstDeparture = legs[0]?.departure_datetime;
      const lastDeparture = legs[legs.length - 1]?.departure_datetime;

      if (firstDeparture) {
        if (flightDetails?.route_type === ROUTE_TYPE.ONEWAY) {
          return TimeUtility.parsedFlightTimeorDate(
            firstDeparture,
            FORMAT_MMM_D
          );
        }
        return (
          TimeUtility.parsedFlightTimeorDate(firstDeparture, FORMAT_MMM_D) +
          " - " +
          TimeUtility.parsedFlightTimeorDate(lastDeparture, FORMAT_MMM_D)
        );
      }
      return "";
    },
    renderFlightRouteType() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return _.capitalize(this.flightDetails?.route_type);
    },
    renderMarketingAirlineLogo() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails;
      return flightDetails?.flight_legs[0]?.segment[0]?.marketing_airline?.logo;
    },
    renderTripSummaryItem() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails;
      const leg = flightDetails?.flight_legs[0] as FlightLeg;

      return {
        departure_date: leg?.departure_datetime,
        arrival_date: leg?.arrival_datetime,
        origin: leg?.origin,
        destination: leg?.destination,
      };
    },
    renderArrival() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails;
      const leg = flightDetails?.flight_legs[
        flightDetails?.flight_legs?.length - 1
      ] as FlightLeg;
      return {
        departure_date: leg?.departure_datetime,
        arrival_date: leg?.arrival_datetime,
        origin: leg?.origin,
        destination: leg?.destination,
      };
    },
    getAllLegs() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails;
      return flightDetails?.flight_legs;
    },
  },
  methods: {
    handleMobileFieldChange(event: InputEvent) {
      const evt = event.target as HTMLInputElement;
      this.$store.commit("updateMobileNumber", evt.value);
    },
    handleSuccessBooking(id: string) {
      this.$router.push(
        formatStringToRoutePath(PATH.FLIGHTS_BOOKING_DETAILS, { id })
      );
    },
    async handleConfirmBooking() {
      try {
        const booking_id = this.$route.params.id as string;
        const mobile_number =
          this.$store.state.flightBookingModule.mobileNumber?.replace(
            /^0(\d{3})\s?(\d{7})$/,
            "+92$1$2"
          );
        const flightTravelers = this.$store.getters.flightTravelers || [];
        const travelers = flightTravelers.map((x: Traveler) => {
          const DOB = format(new Date(x.dob), FORMAT_YYY_MM_DD);
          const PassportExpiry = x?.is_international
            ? format(
                new Date(x.passport_expiry ? x.passport_expiry : new Date()),
                FORMAT_YYY_MM_DD
              )
            : null;
          return {
            ...x,
            dob: DOB,
            passport_expiry: PassportExpiry,
          };
        });

        await bookingFormValidationSchema.validate(
          {
            travelers: travelers,
            mobile_number: this.$store.state.flightBookingModule.mobileNumber,
          },
          { abortEarly: false }
        );

        const payload: IConfirmBooking = {
          contact_details: { mobile_number },
          booking_id,
          travelers,
        };

        analyticsService.logActionEvent(
          FLIGHT_ANALYTICS_EVENTS.AG_FLIGHT_BOOKING_CONFIRMED,
          payload
        );
        this.$store.dispatch("confirmFlightBooking", {
          payload,
          successCallback: this.handleSuccessBooking,
        });
      } catch (ex) {
        if (ex instanceof ValidationError) {
          const err = yupValidationErrorAsSchema(ex);
          this.errors = err;
        }
      }
    },
    renderDate: (date: Date, format = FORMAT_DD_MMM_YYYY_WITH_DASH) => {
      return date ? TimeUtility.parsedFlightTimeorDate(date, format) : "";
    },
  },
  mounted() {
    const booking_id = this.$route.params.id;
    this.$store.dispatch("getFlightBooking", { booking_id });
  },
  beforeMount() {
    window.scrollTo(0, 0);
  },
});
</script>
