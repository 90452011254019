<template>
  <div class="navbar container">
    <a href="#body" class="navbar__logoBox">
      <div class="default-logo">
        <img :src="renderImage()" width="32" height="32" alt="ota logo" />
        <div class="logo-name">
          {{ companyName ? companyName : "Aeroglobe" }}
        </div>
      </div>
    </a>
    <div class="navbar__linksBox">
      <svg
        class="navbar__menuIcon svg-inline--fa fa-bars fa-w-14"
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="bars"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
      >
        <path
          fill="currentColor"
          d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
        ></path>
      </svg>
      <div class="navbar__links">
        <template v-for="item in links" :key="item.name">
          <a
            :href="item.link"
            class="navbar__link"
            @click="handleNavItemClick(item.name)"
            >{{ item.name }}</a
          >
        </template>
      </div>
    </div>
    <a
      href="#contact-section"
      v-if="isValidWhatsAppNumber"
      class="navbar__link navbar__specialLink"
      @click="handleNavItemClick('Contact Us')"
    >
      Contact Us
      <svg
        class="navbar__specialLinkIcon"
        version="1.2"
        baseProfile="tiny-ps"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 29 18"
        width="29"
        height="18"
      >
        <g>
          <path
            fill="currentcolor"
            d="m28.8 8.48l-8.85 8.85l-1.41-1.42l6.29-6.29h-24.94v-2h25.22l-6.29-6.29l1.41-1.42l8.57 8.56z"
          />
        </g>
      </svg>
    </a>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";
import { LinkType } from "@/types";
import analyticsService from "@/analytics.service";
import { WEBSITE_ANALYTICS_EVENTS } from "@/constants/analyticsEvents";
import { WebsiteConfig } from "@/ag-flight-components/types/WebsiteConfig";

export default defineComponent({
  name: "NavBar",
  computed: {
    websiteConfig(): WebsiteConfig {
      return this.$store.getters.websiteConfig;
    },
    isValidWhatsAppNumber() {
      const isValid =
        this.websiteConfig?.contact_detail?.whatsapp_number || null;
      if (isValid === null) {
        return false;
      }
      return true;
    },
  },
  props: {
    links: {
      type: Array as PropType<LinkType[]>,
    },
    image: {
      type: String,
      required: false,
    },
    companyName: {
      type: String,
      default: "Aeroglobe",
    },
  },
  methods: {
    renderImage() {
      return this.image ? this.image : "/assets/images/ota-logo.png";
    },
    handleNavItemClick(item: string) {
      let eventName = WEBSITE_ANALYTICS_EVENTS.WEBSITE_NAVBAR_ITEM_CLICKED;
      let eventData = item;

      analyticsService.logActionEvent(eventName, eventData);
    },
  },
});
</script>

<style></style>
