import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { PATH } from "@/ag-portal-common/constants/path";
import HomeRoutes from "./HomeRoutes";

const routes: Array<RouteRecordRaw> = [
  {
    path: PATH.ROOT,
    name: "homeRoutes",
    meta: {
      isProtected: true,
    },
    props: {
      title: "",
    },
    children: HomeRoutes,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
