import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"font-weight":"bold","color":"#ed6c02","text-align":"center"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_loader = _resolveComponent("ag-loader")!
  const _component_AgDiv = _resolveComponent("AgDiv")!
  const _component_AgSelectedFlightInfo = _resolveComponent("AgSelectedFlightInfo")!
  const _component_AgColumn = _resolveComponent("AgColumn")!
  const _component_AGButton = _resolveComponent("AGButton")!
  const _component_AgRow = _resolveComponent("AgRow")!
  const _component_AgCard = _resolveComponent("AgCard")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_AgPnrDetail = _resolveComponent("AgPnrDetail")!
  const _component_ag_row = _resolveComponent("ag-row")!
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_FlightDetailAccordion = _resolveComponent("FlightDetailAccordion")!
  const _component_PassengerDetailCard = _resolveComponent("PassengerDetailCard")!
  const _component_AdditionalDetailsCard = _resolveComponent("AdditionalDetailsCard")!
  const _component_PriceSummary = _resolveComponent("PriceSummary")!
  const _component_AgSidebarThumb = _resolveComponent("AgSidebarThumb")!
  const _component_Layout = _resolveComponent("Layout")!

  return (_openBlock(), _createBlock(_component_Layout, null, {
    content: _withCtx(() => [
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_ag_loader, { key: 0 }))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_ag_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_ag_column, {
                  xs: "12",
                  sm: "12",
                  md: "8",
                  lg: "9"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_AgCard, { "test-id": "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_AgRow, {
                          "test-id": "",
                          class: "v-center"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_AgColumn, {
                              "test-id": "",
                              md: "9"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_AgSelectedFlightInfo, null, {
                                  pnrInfo: _withCtx(() => [
                                    _createVNode(_component_AgDiv, { class: "booking_id_wrap" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode("Booking ID: "),
                                        _createElementVNode("span", null, _toDisplayString(_ctx.renderBookingId), 1)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_AgColumn, {
                              "test-id": "",
                              md: "3",
                              class: "text-right"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_AGButton, {
                                  "test-id": "",
                                  type: "button",
                                  variant: _ctx.renderStatusSeverity
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.renderBookingStatus), 1)
                                  ]),
                                  _: 1
                                }, 8, ["variant"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ag_column, {
                  xs: "12",
                  sm: "12",
                  md: "4",
                  lg: "3"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_AgCard, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_AgPnrDetail, {
                          "test-id": "",
                          pnr: _ctx.supplierPnr,
                          expiry: _ctx.pnrExpiresAt
                        }, null, 8, ["pnr", "expiry"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ag_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_ag_column, {
                  xs: "12",
                  sm: "12",
                  md: "8",
                  lg: "9"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_AgCard, { "test-id": "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ag_heading, {
                          variant: "h2",
                          title: "Flight Details",
                          class: "margin_bottom_20"
                        }),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.flightDetails?.flight_legs, (flightLeg, index) => {
                          return (_openBlock(), _createBlock(_component_FlightDetailAccordion, {
                            key: index,
                            "flight-leg": flightLeg,
                            index: index
                          }, null, 8, ["flight-leg", "index"]))
                        }), 128))
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_AgCard, { "test-id": "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ag_heading, {
                          variant: "h2",
                          title: "Passenger Details",
                          class: "margin_bottom_20"
                        }),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.flightDetails?.passengers, (passenger, index) => {
                          return (_openBlock(), _createBlock(_component_PassengerDetailCard, {
                            key: index,
                            passenger: passenger,
                            "is-international": _ctx.flightDetails?.is_international
                          }, null, 8, ["passenger", "is-international"]))
                        }), 128))
                      ]),
                      _: 1
                    }),
                    _withDirectives(_createVNode(_component_AgCard, { "test-id": "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ag_heading, {
                          variant: "h2",
                          title: "Additional Services",
                          class: "margin_bottom_20"
                        }),
                        _createVNode(_component_AdditionalDetailsCard, {
                          "has-meal": _ctx.hasMeal,
                          "luggage-info": _ctx.luggageInfo
                        }, null, 8, ["has-meal", "luggage-info"])
                      ]),
                      _: 1
                    }, 512), [
                      [_vShow, _ctx.showAdditionalDetails]
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ag_column, {
                  xs: "12",
                  sm: "12",
                  md: "4",
                  lg: "3"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_AgCard, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_AgSidebarThumb, {
                          "test-id": "",
                          thumb: "sidebarFlightThumb1"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_PriceSummary, null, {
                              flightDetailsActionButtons: _withCtx(() => [
                                (_ctx.isBookingCancelled)
                                  ? (_openBlock(), _createElementBlock("h3", _hoisted_1, " Booking " + _toDisplayString(_ctx.renderBookingStatus), 1))
                                  : (_openBlock(), _createBlock(_component_AGButton, {
                                      key: 1,
                                      "is-loading": _ctx.isDownloadTicketLoading,
                                      onClick: _ctx.onDownloadTicket,
                                      class: "full-width",
                                      "test-id": ""
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode("Download ticket")
                                      ]),
                                      _: 1
                                    }, 8, ["is-loading", "onClick"]))
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ], 64))
    ]),
    _: 1
  }))
}