<template>
  <div class="footer">
    <div class="footer__container container">
      <h2 class="footer__mainTitle" v-if="companyName">
        {{ companyName }}
      </h2>
      <a
        class="footer__subTitle"
        v-if="companyAddress"
        :href="'https://www.google.com/maps/search/' + companyAddress"
        target="_blank"
      >
        {{ companyAddress }}
      </a>
      <div class="footer__links">
        <template v-for="item in links" :key="item.name">
          <a
            :href="item.link"
            class="footer__link"
            @click="handleNavItemClick(item.name)"
            >{{ item.name }}</a
          >
        </template>
      </div>

      <div
        class="footer__socialLinks"
        v-if="
          isApplicableForRow(
            whatsappLink,
            email,
            youtubeLink,
            instagramLink,
            facebookLink
          ) || phone
        "
      >
        <template
          v-if="
            facebookLink &&
            facebookLink.includes('facebook') &&
            isValidLink(facebookLink, 'https://www.facebook.com/')
          "
        >
          <a
            id="facebook"
            :href="facebookLink"
            class="footer__socialLink"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              class="main-grid-item-icon"
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            >
              <path
                d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"
              />
            </svg>
          </a>
        </template>

        <template
          v-if="
            instagramLink &&
            instagramLink.includes('instagram') &&
            isValidLink(instagramLink, 'https://www.instagram.com/')
          "
        >
          <a
            id="instagram"
            :href="instagramLink"
            class="footer__socialLink"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              class="main-grid-item-icon"
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            >
              <rect height="20" rx="5" ry="5" width="20" x="2" y="2" />
              <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />
              <line x1="17.5" x2="17.51" y1="6.5" y2="6.5" />
            </svg>
          </a>
        </template>

        <template
          v-if="
            whatsappLink &&
            whatsappLink.includes('whatsapp') &&
            isValidPhone(whatsappLink)
          "
        >
          <a
            id="whatsapp"
            :href="whatsappLink"
            class="footer__socialLink"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              class="main-grid-item-icon"
              fill="none"
            >
              <path
                d="M6.579 8.121c.209-.663.778-1.457 1.19-1.66.183-.09.319-.11.763-.11.522 0 .548.005.684.14.088.095.328.606.673 1.432.292.71.533 1.315.533 1.347 0 .146-.293.61-.627 1.002-.23.267-.365.47-.365.543 0 .068.167.381.376.69.506.757 1.44 1.696 2.167 2.177.568.376 1.582.867 1.785.867.152 0 .429-.272.992-.982.23-.287.434-.495.512-.511.068-.021.235.005.37.057.392.152 2.371 1.117 2.476 1.211.203.188.037 1.264-.267 1.702-.464.68-1.79 1.259-2.663 1.17-.636-.068-2.14-.564-3.117-1.029-1.253-.6-2.574-1.697-3.644-3.038-.611-.763-1.227-1.692-1.493-2.246-.36-.751-.491-1.331-.455-2 .016-.287.068-.631.11-.762Z"
                fill="white"
              />
              <path
                clip-rule="evenodd"
                d="M.606 9.5C1.582 4.491 5.576.76 10.709.06c.705-.1 2.684-.068 3.368.046.715.126 1.66.371 2.24.59 3.832 1.426 6.663 4.72 7.466 8.683.35 1.729.272 3.755-.203 5.457-1.133 4.03-4.423 7.205-8.511 8.218-2.663.658-5.462.37-7.983-.81l-.617-.292-3.226 1.029C1.473 23.545.01 23.994 0 23.983c-.01-.01.45-1.415 1.029-3.112l1.05-3.096-.424-.84C.48 14.569.12 12.01.605 9.498Zm21.172-.408c-1.028-3.76-4.297-6.626-8.145-7.148-2.099-.282-4.078.037-5.9.956-4.417 2.234-6.522 7.341-4.93 11.957.204.59.752 1.702 1.092 2.213l.271.408-.605 1.775a69.688 69.688 0 0 0-.606 1.817c0 .026.84-.224 1.864-.548a99.767 99.767 0 0 1 1.9-.596c.022 0 .225.11.45.24 2.428 1.447 5.456 1.76 8.187.852a9.927 9.927 0 0 0 6.48-6.945 9.998 9.998 0 0 0-.058-4.98Z"
                fill="white"
                fill-rule="evenodd"
              />
            </svg>
          </a>
        </template>

        <template
          v-if="
            youtubeLink &&
            youtubeLink.includes('you') &&
            isValidLink(youtubeLink, 'https://www.youtube.com/')
          "
        >
          <a
            id="youtube"
            :href="youtubeLink"
            class="footer__socialLink"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              class="main-grid-item-icon"
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            >
              <path
                d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z"
              />
              <polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02" />
            </svg>
          </a>
        </template>

        <template v-if="email && email.includes('@')">
          <a id="email" :href="`mailto:${email}`" class="footer__socialLink">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              class="main-grid-item-icon"
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            >
              <path
                d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
              />
              <polyline points="22,6 12,13 2,6" />
            </svg>
          </a>
        </template>

        <template v-if="phone">
          <a id="phone" :href="`tel:${phone}`" class="footer__socialLink">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              class="main-grid-item-icon"
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            >
              <path
                d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"
              />
            </svg>
          </a>
        </template>
      </div>

      <p class="footer__copyright">
        © {{ getFullYear }} - {{ companyName ? companyName : "Aeroglobe" }}, All
        rights reserved.
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";
import { LinkType } from "@/types";
import { WEBSITE_ANALYTICS_EVENTS } from "@/constants/analyticsEvents";
import analyticsService from "@/analytics.service";

export default defineComponent({
  name: "NavBar",
  props: {
    links: {
      type: Array as PropType<LinkType[]>,
    },
    companyName: {
      type: String,
      default: "Aeroglobe",
    },
    companyAddress: {
      type: String,
      default: "aeroglobe.com.pk",
    },
    facebookLink: {
      type: String,
      default: "null",
    },
    instagramLink: {
      type: String,
      default: "null",
    },
    whatsappLink: {
      type: String,
      default: "null",
    },
    youtubeLink: {
      type: String,
      default: "null",
    },
    email: {
      type: String,
      default: "null",
    },
    phone: {
      type: String,
      default: "null",
    },
  },
  computed: {
    getFullYear() {
      return new Date().getFullYear();
    },
  },
  methods: {
    handleNavItemClick(item: string) {
      let eventName = WEBSITE_ANALYTICS_EVENTS.WEBSITE_NAVBAR_ITEM_CLICKED;
      let eventData = item;

      analyticsService.logActionEvent(eventName, eventData);
    },
    isValidLink(actualLink: string, expectedLink: string) {
      if (
        expectedLink.length === actualLink.length ||
        expectedLink.length > actualLink.length
      ) {
        return false;
      }
      return true;
    },
    isValidPhone(phoneNumber: string | null) {
      if (phoneNumber?.includes("phone=null")) {
        return false;
      }
      return true;
    },
    isApplicableForRow(
      whatsapp: string,
      email: string,
      youtube: string,
      instagram: string,
      facebook: string
    ) {
      if (
        this.isValidPhone(whatsapp) ||
        (email && email.includes("@")) ||
        this.isValidLink(youtube, "https://www.youtube.com/") ||
        this.isValidLink(instagram, "https://www.instagram.com/") ||
        this.isValidLink(facebook, "https://www.facebook.com/")
      ) {
        return true;
      }
      return false;
    },
  },
});
</script>

<style></style>
