<template>
  <main id="doob-theme" class="body">
    <NavBar :links="links" :company-name="companyName" :image="websiteLogo" />

    <MyHeader :title="heading" :description="subHeading" />

    <template v-if="showServices">
      <MyServices :services="services" @on:select="onClickItemHandler" />
    </template>

    <AboutUs
      :title="aboutUsHeading"
      :description="aboutUsSubHeading"
      :image-url="aboutUsImageUrl"
    />

    <template v-if="showPackages">
      <MyPackages :packages="packages" @on:select="onClickItemHandler" />
    </template>

    <ContactUs :phone="phone" />

    <MyFooter
      :links="links"
      :company-name="companyName"
      :company-address="companyAddress"
      :facebook-link="facebookLink"
      :instagram-link="instagramLink"
      :whatsapp-link="whatsappLink"
      :youtube-link="youtubeLink"
      :email="email"
      :phone="phone"
    />

    <ImageLightBox v-model:state="imageModal" :image-src="selectedImageSrc" />
  </main>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";
import NavBar from "./components/NavBar.vue";
import MyHeader from "./components/Header.vue";
import MyServices from "./components/Services.vue";
import AboutUs from "./components/AboutUs.vue";
import MyPackages from "./components/Packages.vue";
import ContactUs from "./components/ContactUs.vue";
import MyFooter from "./components/Footer.vue";
import { WebsiteConfig } from "@/ag-flight-components/types/WebsiteConfig";
import ImageLightBox from "@/components/ImageLightBox.vue";

export default defineComponent({
  name: "DoobTheme",
  components: {
    NavBar,
    MyHeader,
    MyServices,
    AboutUs,
    MyPackages,
    ContactUs,
    MyFooter,
    ImageLightBox,
  },
  props: {
    websiteConfig: {
      type: Object as PropType<WebsiteConfig>,
      required: true,
    },
  },
  data() {
    return {
      links: [
        { name: "Home", link: "#body" },
        { name: "About us", link: "#about-section" },
      ],
      imageModal: false,
      selectedImageSrc: "",
    };
  },
  computed: {
    websiteLogo() {
      return this.websiteConfig?.self_preferences?.logo;
    },
    companyName() {
      return this.websiteConfig?.self_preferences?.display_name;
    },
    companyAddress() {
      return this.websiteConfig?.contact_detail?.physical_address ?? undefined;
    },
    heading() {
      return this.websiteConfig?.header_section_data?.heading_text ?? undefined;
    },
    subHeading() {
      return this.websiteConfig?.header_section_data?.body_text ?? undefined;
    },
    aboutUsHeading() {
      return this.websiteConfig?.about_us?.title ?? undefined;
    },
    aboutUsSubHeading() {
      return this.websiteConfig?.about_us?.description ?? undefined;
    },
    services() {
      return this.websiteConfig?.services_detail.service_list;
    },
    packages() {
      return this.websiteConfig?.packages_detail.package_list as any[];
    },
    aboutUsImageUrl() {
      return this.websiteConfig?.about_us?.image_url ?? undefined;
    },
    facebookLink() {
      return this.websiteConfig?.social_links?.facebook ?? undefined;
    },
    instagramLink() {
      return this.websiteConfig?.social_links?.instagram ?? undefined;
    },
    whatsappLink() {
      if (this.websiteConfig?.contact_detail?.whatsapp_number === "") {
        return `https://api.whatsapp.com/send/?phone=null`;
      }
      return `https://api.whatsapp.com/send/?phone=${this.websiteConfig?.contact_detail?.whatsapp_number}`;
    },
    youtubeLink() {
      return this.websiteConfig?.social_links?.youtube ?? undefined;
    },
    email() {
      return this.websiteConfig?.contact_detail?.email_address ?? undefined;
    },
    phone() {
      return this.websiteConfig?.contact_detail?.mobile_number ?? undefined;
    },
    showPackages(): boolean {
      return (
        this.websiteConfig?.packages_detail?.is_enabled &&
        Boolean(this.websiteConfig?.packages_detail?.package_list?.length)
      );
    },
    showServices(): boolean {
      return (
        this.websiteConfig?.services_detail?.is_enabled &&
        Boolean(this.websiteConfig?.services_detail?.service_list?.length)
      );
    },
  },
  methods: {
    renderImage(image: string | undefined) {
      return image ? image : "/assets/images/umrahPic.jpg";
    },
    onClickItemHandler(image: string) {
      this.selectedImageSrc = this.renderImage(image);
      this.imageModal = true;
    },
  },
  mounted() {
    if (
      this.websiteConfig?.packages_detail?.is_enabled &&
      Boolean(this.websiteConfig?.packages_detail?.package_list?.length)
    ) {
      const isExists = this.links.filter((li) => li.name === "Packages");

      if (!isExists.length) {
        this.links.push({ name: "Packages", link: "#package-section" });
      }
    }

    if (
      this.websiteConfig?.services_detail?.is_enabled &&
      Boolean(this.websiteConfig?.services_detail?.service_list?.length)
    ) {
      const isExists = this.links.filter((li) => li.name === "Services");

      if (!isExists.length) {
        this.links.push({ name: "Services", link: "#services-section" });
      }
    }
  },
});
</script>

<style scoped lang="css">
@import url("./Doob.style.css");
</style>
