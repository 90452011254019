import mixpanel from "mixpanel-browser";

type EVENT_DATA = {
  [key: string]: string | number;
};

const ANALYTICS_PREFIX_FLIGHT = "OTA-";

class AnalyticsService {
  private shouldLogEvents: boolean;
  private analyticPrefix: string;

  constructor(prefix: string) {
    this.analyticPrefix = prefix;
    const mixpanel_key = process.env.VUE_APP_MIXPANEL_KEY as undefined | string;
    const envAppEventLoggingEnabled = process.env
      .VUE_APP_EVENT_LOGGING_ENABLED as undefined | string;
    this.shouldLogEvents = envAppEventLoggingEnabled == "true";
    if (mixpanel_key) {
      mixpanel.init(mixpanel_key);
    }
  }

  logPageViewEvent(screen_name: string, page_name: string, data?: EVENT_DATA) {
    this.logEvent(page_name, {
      ...(data || {}),
      screen_name,
    });
  }

  private logEvent(eventName: string, data: EVENT_DATA = {}) {
    data = {
      ...data,
    };
    eventName = this.analyticPrefix + eventName;
    if (this.shouldLogEvents) {
      mixpanel.track(eventName, data);
    }
  }

  logActionEvent = (event_name: string, data?: any) => {
    this.logEvent(event_name, data);
  };

  getSubdomain() {
    const hostname = window.location.hostname;
    const parts = hostname.split(".");
    if (parts.length >= 1) {
      return parts[0];
    } else {
      return "No Subdomain";
    }
  }
}

export default new AnalyticsService(ANALYTICS_PREFIX_FLIGHT);
