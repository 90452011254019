import RestClientService from "@/ag-portal-common/services/restClient.service";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import { GET_WEBSITE_CONFIG } from "../constants/apiEndPoints";
import { formatString } from "@/ag-portal-common/utils/helpers";

class WebsiteService {
  private restClient: RestClientService;

  constructor() {
    this.restClient = new RestClientService();
  }

  public get(orgId: string): Promise<IAGResponse<any>> {
    return this.restClient.get(formatString(GET_WEBSITE_CONFIG.GET, orgId));
  }
}

export default WebsiteService;
