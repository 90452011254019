import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b31addd"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "doob-theme",
  class: "body"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_MyHeader = _resolveComponent("MyHeader")!
  const _component_MyServices = _resolveComponent("MyServices")!
  const _component_AboutUs = _resolveComponent("AboutUs")!
  const _component_MyPackages = _resolveComponent("MyPackages")!
  const _component_ContactUs = _resolveComponent("ContactUs")!
  const _component_MyFooter = _resolveComponent("MyFooter")!
  const _component_ImageLightBox = _resolveComponent("ImageLightBox")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createVNode(_component_NavBar, {
      links: _ctx.links,
      "company-name": _ctx.companyName,
      image: _ctx.websiteLogo
    }, null, 8, ["links", "company-name", "image"]),
    _createVNode(_component_MyHeader, {
      title: _ctx.heading,
      description: _ctx.subHeading
    }, null, 8, ["title", "description"]),
    (_ctx.showServices)
      ? (_openBlock(), _createBlock(_component_MyServices, {
          key: 0,
          services: _ctx.services,
          "onOn:select": _ctx.onClickItemHandler
        }, null, 8, ["services", "onOn:select"]))
      : _createCommentVNode("", true),
    _createVNode(_component_AboutUs, {
      title: _ctx.aboutUsHeading,
      description: _ctx.aboutUsSubHeading,
      "image-url": _ctx.aboutUsImageUrl
    }, null, 8, ["title", "description", "image-url"]),
    (_ctx.showPackages)
      ? (_openBlock(), _createBlock(_component_MyPackages, {
          key: 1,
          packages: _ctx.packages,
          "onOn:select": _ctx.onClickItemHandler
        }, null, 8, ["packages", "onOn:select"]))
      : _createCommentVNode("", true),
    _createVNode(_component_ContactUs, { phone: _ctx.phone }, null, 8, ["phone"]),
    _createVNode(_component_MyFooter, {
      links: _ctx.links,
      "company-name": _ctx.companyName,
      "company-address": _ctx.companyAddress,
      "facebook-link": _ctx.facebookLink,
      "instagram-link": _ctx.instagramLink,
      "whatsapp-link": _ctx.whatsappLink,
      "youtube-link": _ctx.youtubeLink,
      email: _ctx.email,
      phone: _ctx.phone
    }, null, 8, ["links", "company-name", "company-address", "facebook-link", "instagram-link", "whatsapp-link", "youtube-link", "email", "phone"]),
    _createVNode(_component_ImageLightBox, {
      state: _ctx.imageModal,
      "onUpdate:state": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.imageModal) = $event)),
      "image-src": _ctx.selectedImageSrc
    }, null, 8, ["state", "image-src"])
  ]))
}