<template>
  <Layout>
    <template #content>
      <div class="page_container">
        <ag-loader v-if="flightsLoading" />
        <template v-else>
          <ResultTimeoutModal
            :is-open="timerTimeOuted"
            :handle-refresh-results="handleRefreshResults"
          />
          <SearchedFlightInfo :trip-date="tripDate">
            <template #timer>
              <Timer v-show="showTimer" @timer:timeouted="handleTimerTimeOut" />
            </template>
          </SearchedFlightInfo>

          <Filters
            :selected-filters="selectedFilters"
            :handle-change-search-dialog-open="handleChangeSearchDialogOpen"
            :handle-update-range="handleUpdateRange"
            @updateSelectedFilters="updateSelectedFilters"
          />

          <FlightSearchChips
            :selected-filters="selectedFilters"
            :handle-remove-chip="handleRemoveChip"
          >
          </FlightSearchChips>

          <!-- Selected Legs -->
          <SelectedFlightLegs />

          <!-- Flight List Mapping -->
          <AgCard
            test-id=""
            v-for="item in filteredFlights"
            :key="item.flight_numbers[0] + item.departure_time"
          >
            <FlightDetailCardItem
              @selectedDialogItem="handleFlightFareSelect"
              :flightItem="item"
              :showFareBreakDown="false"
            />
          </AgCard>

          <!-- Results no found -->
          <AgNotFound
            v-show="showNoReults"
            test-id=""
            heading="No Results Found"
            description="Please Try Modify Your Filters OR Try Again"
          />
        </template>
        <FareBookingDialog
          :is-open="isBookingDialogOpen"
          :flight-item="selectedFlightItemForDialog"
          :fare-option="selectedFareForDialog"
          @handleDialogClose="handleCloseBookingDialog"
        />
        <FlightChangeSearch
          :is-open="isChangeSearchDialogOpen"
          :handle-close="handleChangeSearchDialogClose"
        />
      </div>
    </template>
  </Layout>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import _ from "lodash";

import {
  FareOption,
  FlightOption,
  SelectedFlightSearchQueryFilters,
  SelectedFlightLeg,
} from "@/ag-flight-components/types/Flights";
import { JourneyLeg } from "@/ag-flight-components/types/JourneyLeg";
import {
  formatMultiCityQueryParamToArray,
  formatTripDates,
  filterFlightsByAirlines,
  filterFlightsByDepartureTime,
  filterFlightsByStops,
  filterFlightsByPrice,
} from "@/ag-flight-components/utils";
import { ROUTE_TYPE } from "@/ag-flight-components/enums/route_type";
import SearchedFlightInfo from "@/ag-flight-components/components/FlightSearchResults/SearchedFlightInfo.vue";
import Filters from "@/ag-flight-components/components/FlightSearchResults/Filters.vue";
import FlightSearchChips from "@/ag-flight-components/components/FlightSearchResults/FlightSearchChips.vue";
import SelectedFlightLegs from "@/ag-flight-components/components/FlightSearchResults/SelectedFlightLegs.vue";
import FlightChangeSearch from "@/ag-flight-components/components/FlightSearchResults/ChangeSearch.vue";
import Timer from "@/ag-flight-components/components/FlightSearchResults/Timer.vue";
import ResultTimeoutModal from "@/ag-flight-components/components/FlightSearchResults/TimeoutModal.vue";
import FlightDetailCardItem from "@/ag-flight-components/components/FlightSearchResults/FlightDetailCardItem.vue";
import FareBookingDialog from "@/ag-flight-components/components/FlightSearchResults/FareBookingDialog.vue";
import Layout from "@/components/Layout.vue";

export default defineComponent({
  name: "FlightSearchResult",
  components: {
    SearchedFlightInfo,
    Filters,
    FlightSearchChips,
    SelectedFlightLegs,
    FlightChangeSearch,
    Timer,
    ResultTimeoutModal,
    FlightDetailCardItem,
    FareBookingDialog,
    Layout,
  },

  data(): {
    filteredFlights: Array<FlightOption>;
    tripDate: string;
    timerTimeOuted: boolean;
    selectedFilters: SelectedFlightSearchQueryFilters;
    isBookingDialogOpen: boolean;
    currentSelectedFlightOptionForDialog: FlightOption | null;
    selectedFareForDialog: FareOption | null;
    selectedFlightItemForDialog: FlightOption | null;
    isChangeSearchDialogOpen: boolean;
    allowRouterQueryRequest: boolean;
  } {
    return {
      tripDate: "",
      timerTimeOuted: false,
      isBookingDialogOpen: false,
      isChangeSearchDialogOpen: false,
      selectedFareForDialog: null,
      selectedFlightItemForDialog: null,
      selectedFilters: {
        airlines: [],
        stops: [],
        priceRange: [],
        departureTime: [],
        maxPriceRangeSlider: 0,
      },
      filteredFlights: [],
      currentSelectedFlightOptionForDialog: null,
      allowRouterQueryRequest: true,
    };
  },

  computed: {
    flightsLoading() {
      return this.$store.getters.isFlightsLoading;
    },
    filters() {
      return this.$store.getters.filters;
    },
    currentJourneyLeg() {
      return this.$store.getters.currentJourneyLeg;
    },
    selectedFlightFilters() {
      return this.$store.getters.allSelectdFlightFilters;
    },
    selectedAirlineFilters() {
      return this.$store.getters.selectedAirlineFilters;
    },
    selectedFlightLegs(): Array<SelectedFlightLeg> {
      return this.$store.state.flightModule.selectedFlightLegs;
    },
    showTimer() {
      const isLoading = this.$store.getters.isFlightsLoading;
      const flights = this.$store.getters.flights;
      return !isLoading && flights.length > 0;
    },
    showNoReults() {
      const isLoading = this.$store.getters.isFlightsLoading;
      const flights = this.$store.getters.flights;
      return !isLoading && flights.length < 1;
    },
    allJourneyLegs() {
      return this.$store.getters.allJourneyLegs;
    },
  },

  methods: {
    handleTimerTimeOut(value: boolean) {
      this.timerTimeOuted = value;
    },
    async fetchFlights() {
      this.$store.commit("clearSelectedFlightLegs");
      const fullPath = this.$route.fullPath;

      const {
        route_type,
        adult,
        child,
        infant,
        cabin_class,
        from_chip,
        trips,
      } = this.$route.query;
      const tripsString = trips?.toString() || "";

      const [origin, destination, departure_date, return_date] =
        tripsString.split(",");

      this.tripDate = formatTripDates(tripsString, route_type as string);

      const normalFlightPayload = {
        origin,
        destination,
        departure_date,
        return_date: return_date ? return_date : null,
      };

      const legs = formatMultiCityQueryParamToArray(trips as string);

      const multiCityFlightPayload = {
        legs,
      };

      const payload = {
        route_type: route_type,
        traveler_count: {
          adult_count: adult,
          child_count: child,
          infant_count: infant,
        },
        cabin_class: cabin_class,
        from_chip: from_chip,
        non_stop_flight: false,
        ...([ROUTE_TYPE.ONEWAY, ROUTE_TYPE.RETURN].includes(
          route_type as ROUTE_TYPE
        ) && {
          ...normalFlightPayload,
        }),
        ...(route_type === ROUTE_TYPE.MULTI_CITY && {
          ...multiCityFlightPayload,
        }),
      };

      this.$store.dispatch("fetchFlights", payload);
    },
    handleRefreshResults() {
      this.timerTimeOuted = false;
      this.fetchFlights();
    },
    handleFilters(
      journeyLeg: JourneyLeg,
      filter: SelectedFlightSearchQueryFilters
    ) {
      let tempFilteredData: Array<FlightOption> = this.filteredFlights;

      tempFilteredData = filterFlightsByAirlines(
        journeyLeg.flight_options,
        filter.airlines
      );
      tempFilteredData = filterFlightsByDepartureTime(
        tempFilteredData,
        filter.departureTime
      );
      tempFilteredData = filterFlightsByStops(tempFilteredData, filter.stops);
      tempFilteredData = filterFlightsByPrice(
        tempFilteredData,
        filter.priceRange[0],
        filter.maxPriceRangeSlider ?? 0
      );

      return tempFilteredData;
    },
    handleRemoveChip(
      index: number,
      filter: "airlines" | "stops" | "departureTime" | "priceRange"
    ) {
      const selectedFilter = this.selectedFilters[filter];
      selectedFilter.splice(index, 1);
    },
    filterFlightsByFareOptionIds(
      flightOptions: FlightOption[],
      nextFareIds: Array<string>
    ): Array<FlightOption> {
      return flightOptions.filter((item) => {
        item.fare_options = item.fare_options.filter((fare_opt) => {
          return nextFareIds.includes(fare_opt.pre_booking_token.split("_")[0]);
        });

        return item.fare_options.length ? true : false;
      });
    },
    handleFlightFareSelect(fareOption: FareOption, flightOption: FlightOption) {
      this.selectedFareForDialog = fareOption;
      this.selectedFlightItemForDialog = flightOption;
    },
    handleCloseBookingDialog() {
      this.isBookingDialogOpen = false;
      this.selectedFareForDialog = null;
      this.selectedFlightItemForDialog = null;
    },
    handleUpdateRange(val: number) {
      this.selectedFilters.maxPriceRangeSlider = val;
    },
    handleChangeSearchDialogClose() {
      this.isChangeSearchDialogOpen = false;
    },
    handleChangeSearchDialogOpen() {
      this.isChangeSearchDialogOpen = true;
      this.allowRouterQueryRequest = true;
    },
    updateSelectedFilters(updatedFilters: SelectedFlightSearchQueryFilters) {
      this.selectedFilters = updatedFilters;
    },
  },

  watch: {
    // Watches filters on flight Search
    selectedFilters: {
      handler: function (filter) {
        const journeyLeg = this.currentJourneyLeg;
        const filteredData = this.handleFilters(journeyLeg, filter);
        this.filteredFlights = filteredData;
      },
      deep: true,
    },
    // Loads filters based on the results to the local state
    "$store.state.flightModule.selectedFlightFilters": {
      handler: function (selectedFilters) {
        this.selectedFilters = _.cloneDeep(selectedFilters);
      },
    },
    // Returns Searched Flights from the current state
    "$store.state.flightModule.selectedFilteredLegsByFareIds": {
      handler: function (legs: Array<FlightOption[]>) {
        const currentLeg = this.$store.state.flightModule.currentLegIndex;
        this.filteredFlights = legs[currentLeg];
      },
      deep: true,
    },
    // Watches when fare is selected
    "$store.state.flightModule.selectedFares": {
      handler: function (fares: FareOption[]) {
        const { currentLegIndex, lastLegIndex, flights } =
          this.$store.state.flightModule;

        if (fares.length) {
          const fare = fares[fares.length - 1];
          const allJourneyLegs = this.$store.getters.allJourneyLegs;

          if (currentLegIndex < lastLegIndex) {
            const nextLegFareIds = fare.next_fare_option_ids;

            const nextLeg = _.cloneDeep(
              allJourneyLegs[currentLegIndex + 1] as JourneyLeg
            );

            if (nextLegFareIds.length) {
              const nextLegFlights = this.filterFlightsByFareOptionIds(
                nextLeg.flight_options,
                nextLegFareIds
              );

              this.$store.commit("saveFilteredLegsByFareIds", nextLegFlights);
              this.filteredFlights = nextLegFlights;
            } else {
              this.filteredFlights = nextLeg.flight_options;
            }
            this.$store.commit("saveCurrentLegIndex", currentLegIndex + 1);
          }
        } else {
          this.filteredFlights = flights;
        }
      },
      deep: true,
    },
    selectedFareForDialog: {
      handler: function (newVal, oldVal) {
        const newItem = _.cloneDeep(newVal);
        const oldItem = _.cloneDeep(oldVal);

        if (newItem && newItem !== oldItem) {
          this.isBookingDialogOpen = true;
        } else {
          this.isBookingDialogOpen = false;
        }
      },
    },
    "$route.query": {
      handler: function () {
        if (this.allowRouterQueryRequest) {
          this.fetchFlights();
          this.allowRouterQueryRequest = false;
        }
      },
      immediate: true,
    },
  },

  mounted() {
    this.$store.dispatch("fetchAirports", "Pakistan");
  },

  unmounted() {
    this.$store.commit("clearSelectedFlightLegs");
  },
  beforeMount() {
    window.scrollTo(0, 0);
    this.$store.dispatch("fetchAirports", "Pakistan");
    this.$store.commit("clearSelectedFlightLegs");
  },
});
</script>
