import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "page_container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_loader = _resolveComponent("ag-loader")!
  const _component_ResultTimeoutModal = _resolveComponent("ResultTimeoutModal")!
  const _component_Timer = _resolveComponent("Timer")!
  const _component_SearchedFlightInfo = _resolveComponent("SearchedFlightInfo")!
  const _component_Filters = _resolveComponent("Filters")!
  const _component_FlightSearchChips = _resolveComponent("FlightSearchChips")!
  const _component_SelectedFlightLegs = _resolveComponent("SelectedFlightLegs")!
  const _component_FlightDetailCardItem = _resolveComponent("FlightDetailCardItem")!
  const _component_AgCard = _resolveComponent("AgCard")!
  const _component_AgNotFound = _resolveComponent("AgNotFound")!
  const _component_FareBookingDialog = _resolveComponent("FareBookingDialog")!
  const _component_FlightChangeSearch = _resolveComponent("FlightChangeSearch")!
  const _component_Layout = _resolveComponent("Layout")!

  return (_openBlock(), _createBlock(_component_Layout, null, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.flightsLoading)
          ? (_openBlock(), _createBlock(_component_ag_loader, { key: 0 }))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createVNode(_component_ResultTimeoutModal, {
                "is-open": _ctx.timerTimeOuted,
                "handle-refresh-results": _ctx.handleRefreshResults
              }, null, 8, ["is-open", "handle-refresh-results"]),
              _createVNode(_component_SearchedFlightInfo, { "trip-date": _ctx.tripDate }, {
                timer: _withCtx(() => [
                  _withDirectives(_createVNode(_component_Timer, { "onTimer:timeouted": _ctx.handleTimerTimeOut }, null, 8, ["onTimer:timeouted"]), [
                    [_vShow, _ctx.showTimer]
                  ])
                ]),
                _: 1
              }, 8, ["trip-date"]),
              _createVNode(_component_Filters, {
                "selected-filters": _ctx.selectedFilters,
                "handle-change-search-dialog-open": _ctx.handleChangeSearchDialogOpen,
                "handle-update-range": _ctx.handleUpdateRange,
                onUpdateSelectedFilters: _ctx.updateSelectedFilters
              }, null, 8, ["selected-filters", "handle-change-search-dialog-open", "handle-update-range", "onUpdateSelectedFilters"]),
              _createVNode(_component_FlightSearchChips, {
                "selected-filters": _ctx.selectedFilters,
                "handle-remove-chip": _ctx.handleRemoveChip
              }, null, 8, ["selected-filters", "handle-remove-chip"]),
              _createVNode(_component_SelectedFlightLegs),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredFlights, (item) => {
                return (_openBlock(), _createBlock(_component_AgCard, {
                  "test-id": "",
                  key: item.flight_numbers[0] + item.departure_time
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_FlightDetailCardItem, {
                      onSelectedDialogItem: _ctx.handleFlightFareSelect,
                      flightItem: item,
                      showFareBreakDown: false
                    }, null, 8, ["onSelectedDialogItem", "flightItem"])
                  ]),
                  _: 2
                }, 1024))
              }), 128)),
              _withDirectives(_createVNode(_component_AgNotFound, {
                "test-id": "",
                heading: "No Results Found",
                description: "Please Try Modify Your Filters OR Try Again"
              }, null, 512), [
                [_vShow, _ctx.showNoReults]
              ])
            ], 64)),
        _createVNode(_component_FareBookingDialog, {
          "is-open": _ctx.isBookingDialogOpen,
          "flight-item": _ctx.selectedFlightItemForDialog,
          "fare-option": _ctx.selectedFareForDialog,
          onHandleDialogClose: _ctx.handleCloseBookingDialog
        }, null, 8, ["is-open", "flight-item", "fare-option", "onHandleDialogClose"]),
        _createVNode(_component_FlightChangeSearch, {
          "is-open": _ctx.isChangeSearchDialogOpen,
          "handle-close": _ctx.handleChangeSearchDialogClose
        }, null, 8, ["is-open", "handle-close"])
      ])
    ]),
    _: 1
  }))
}