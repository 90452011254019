import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_loader = _resolveComponent("ag-loader")!
  const _component_AgSelectedFlightInfo = _resolveComponent("AgSelectedFlightInfo")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_AgDiv = _resolveComponent("AgDiv")!
  const _component_ag_row = _resolveComponent("ag-row")!
  const _component_AgCard = _resolveComponent("AgCard")!
  const _component_AgIconInfoBar = _resolveComponent("AgIconInfoBar")!
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_AgPhoneField = _resolveComponent("AgPhoneField")!
  const _component_AgColumn = _resolveComponent("AgColumn")!
  const _component_AgRow = _resolveComponent("AgRow")!
  const _component_AgHeading = _resolveComponent("AgHeading")!
  const _component_AgTravelDetail = _resolveComponent("AgTravelDetail")!
  const _component_ag_accordion_panel = _resolveComponent("ag-accordion-panel")!
  const _component_ag_accordion = _resolveComponent("ag-accordion")!
  const _component_TavelerCard = _resolveComponent("TavelerCard")!
  const _component_AGButton = _resolveComponent("AGButton")!
  const _component_PriceSummary = _resolveComponent("PriceSummary")!
  const _component_ag_card = _resolveComponent("ag-card")!
  const _component_TripSummary = _resolveComponent("TripSummary")!
  const _component_Layout = _resolveComponent("Layout")!

  return (_openBlock(), _createBlock(_component_Layout, null, {
    content: _withCtx(() => [
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_ag_loader, { key: 0 }))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_AgCard, { "test-id": "" }, {
              default: _withCtx(() => [
                _createVNode(_component_ag_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ag_column, {
                      xs: "12",
                      sm: "12",
                      md: "8",
                      lg: "9"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_AgSelectedFlightInfo, {
                          date: _ctx.formatTripDates,
                          adult: 
                  _ctx.$store.state.flightBookingModule.flightDetails?.adult_count
                ,
                          children: 
                  _ctx.$store.state.flightBookingModule.flightDetails?.child_count
                ,
                          infant: 
                  _ctx.$store.state.flightBookingModule.flightDetails?.infant_count
                
                        }, {
                          destination: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.formatLegsInfo), 1)
                          ]),
                          _: 1
                        }, 8, ["date", "adult", "children", "infant"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ag_column, {
                      xs: "12",
                      sm: "12",
                      md: "3"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_AgSelectedFlightInfo, null, {
                          pnrInfo: _withCtx(() => [
                            _createVNode(_component_AgDiv, { class: "booking_id_wrap" }, {
                              default: _withCtx(() => [
                                _createTextVNode("Booking Confirmation "),
                                _createElementVNode("span", null, _toDisplayString(_ctx.renderBookingId), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_AgRow, { "test-id": "" }, {
              default: _withCtx(() => [
                _createVNode(_component_AgColumn, {
                  "test-id": "",
                  md: "8",
                  lg: "9"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_AgCard, { "test-id": "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_AgIconInfoBar, {
                          icon: "contactPhoneIcon",
                          title: "Contact Details",
                          "test-id": ""
                        }),
                        _createVNode(_component_ag_accordion, {
                          class: "flight_accordion_wrap margin_bottom_0",
                          "panel-value": [0]
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ag_accordion_panel, null, {
                              flightInfo: _withCtx(() => [
                                _createVNode(_component_ag_heading, {
                                  variant: "h3",
                                  title: "Lead Traveler",
                                  class: "margin_bottom_0"
                                })
                              ]),
                              tabSection: _withCtx(() => [
                                _createVNode(_component_AgRow, {
                                  "test-id": "",
                                  class: "padding_top_20"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_AgColumn, {
                                      "test-id": "",
                                      md: "7",
                                      lg: "7"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_AgRow, { "test-id": "" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_AgColumn, {
                                              "test-id": "",
                                              md: "12",
                                              lg: "9"
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_AgPhoneField, {
                                                  "test-id": "",
                                                  "default-country-code": "PK",
                                                  "info-message": "Please input the traveler’s\n                          number here to receive flight updates",
                                                  onInput: _ctx.handleMobileFieldChange,
                                                  error: _ctx.renderMobileNumberError
                                                }, null, 8, ["onInput", "error"])
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_AgColumn, {
                                      "test-id": "",
                                      md: "5",
                                      lg: "5"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_AgTravelDetail, {
                                          "test-id": "",
                                          items: {
                            'Mobile Number':
                              _ctx.$store.state.flightBookingModule.mobileNumber,
                          }
                                        }, {
                                          headingArea: _withCtx(() => [
                                            _createVNode(_component_AgHeading, {
                                              variant: "h2",
                                              title: "Contact Details",
                                              class: "margin_bottom_5"
                                            })
                                          ]),
                                          _: 1
                                        }, 8, ["items"])
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.travelers, (traveler, index) => {
                      return (_openBlock(), _createBlock(_component_TavelerCard, {
                        traveler: traveler,
                        errors: _ctx.errors,
                        index: index,
                        key: index
                      }, null, 8, ["traveler", "errors", "index"]))
                    }), 128)),
                    _createVNode(_component_AgCard, { "test-id": "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_AgDiv, {
                          "test-id": "",
                          class: "text-right"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_AGButton, {
                              "test-id": "",
                              type: "button",
                              onClick: _ctx.handleConfirmBooking,
                              "is-loading": _ctx.isConfirmBookingLoading
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode("Confirm Booking")
                              ]),
                              _: 1
                            }, 8, ["onClick", "is-loading"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_AgColumn, {
                  "test-id": "",
                  md: "4",
                  lg: "3"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ag_card, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_PriceSummary)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ag_heading, {
                      variant: "h2",
                      title: "Trip Summary",
                      class: "margin_bottom_10"
                    }),
                    _createVNode(_component_TripSummary, { legs: _ctx.getAllLegs }, null, 8, ["legs"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ], 64))
    ]),
    _: 1
  }))
}