import { IFlightBooking } from "@/ag-flight-components/interfaces/IFlightBooking.interface";

const flightBookingState: IFlightBooking = {
  isLoading: false,
  confirmBookingLoading: false,
  cancelBookingLoading: false,
  downloadTicketLoading: false,
  travelers: [],
  mobileNumber: null,
  flightDetails: null,
  bookingStatus: "",
  pnrExpiryDate: null,
  supplierPnr: null,
  email: null,
  voidTicketLoading: false,
  isPassengerLoading: false,
  passengers: null,
  errors: null,
};

export default flightBookingState;
