<template>
  <div class="footer bg-gray" id="contactus">
    <img
      class="decoration-circles"
      src="/assets/images/decoration-circles.png"
      alt="alternative"
    />
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h4>Find & follow us on</h4>
          <div class="social-container">
            <span class="fa-stack">
              <a
                :href="websiteConfig?.social_links?.facebook"
                v-if="websiteConfig?.social_links?.facebook"
                @click="handleFooterIconsClick('facebook')"
                target="_blank"
              >
                <i class="fas fa-circle fa-stack-2x"></i>
                <i class="fab fa-facebook-f fa-stack-1x"></i>
              </a>
            </span>

            <span class="fa-stack">
              <a
                :href="websiteConfig?.social_links?.instagram"
                v-if="websiteConfig?.social_links?.instagram"
                @click="handleFooterIconsClick('instagram')"
                target="_blank"
              >
                <i class="fas fa-circle fa-stack-2x"></i>
                <i class="fab fa-instagram fa-stack-1x"></i>
              </a>
            </span>
            <span class="fa-stack">
              <a
                :href="renderWhatsapp"
                v-if="
                  isValidPhone(
                    websiteConfig?.contact_detail?.whatsapp_number || null
                  )
                "
                @click="handleFooterIconsClick('whatsapp')"
                target="_blank"
              >
                <i class="fas fa-circle fa-stack-2x"></i>
                <i class="fab fa-whatsapp fa-stack-1x"></i>
              </a>
            </span>
            <span class="fa-stack">
              <a
                :href="websiteConfig?.social_links?.youtube"
                v-if="websiteConfig?.social_links?.youtube"
                @click="handleFooterIconsClick('whatsapp')"
                target="_blank"
              >
                <i class="fas fa-circle fa-stack-2x"></i>
                <i class="fab fa-youtube fa-stack-1x"></i>
              </a>
            </span>

            <span class="fa-stack">
              <a
                :href="'mailto:' + websiteConfig?.contact_detail?.email_address"
                v-if="websiteConfig?.contact_detail?.email_address"
                @click="handleFooterIconsClick('mail')"
                target="_blank"
              >
                <i class="fas fa-circle fa-stack-2x"></i>
                <i class="fa fa-envelope fa-stack-1x"></i>
              </a>
            </span>

            <span class="fa-stack">
              <a
                :href="'tel:' + websiteConfig?.contact_detail?.mobile_number"
                v-if="websiteConfig?.contact_detail?.mobile_number"
                @click="handleFooterIconsClick('mobile')"
                target="_blank"
              >
                <i class="fas fa-circle fa-stack-2x"></i>
                <i class="fa fa-phone fa-stack-1x"></i>
              </a>
            </span>
          </div>
          <h5>Address</h5>
          <p class="mb-2" style="text-align: center">
            {{ websiteConfig?.contact_detail?.physical_address }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="copyright bg-gray">
    <div class="container">
      <p class="mb-2" style="text-align: center">Copyright © Reserved</p>
    </div>
  </div>
</template>

<script lang="ts">
import { WebsiteConfig } from "@/ag-flight-components/types/WebsiteConfig";
import analyticsService from "@/analytics.service";
import { WEBSITE_ANALYTICS_EVENTS } from "@/constants/analyticsEvents";
import { defineComponent } from "vue";

export default defineComponent({
  name: "FooterComponent",
  computed: {
    websiteConfig(): WebsiteConfig {
      return this.$store.getters.websiteConfig;
    },
    renderWhatsapp(): string {
      return `https://api.whatsapp.com/send/?phone=${this.websiteConfig?.contact_detail?.whatsapp_number}`;
    },
  },
  methods: {
    isValidPhone(phoneNumber: string | null) {
      if (phoneNumber?.includes("phone=null")) {
        return false;
      }
      return true;
    },
    handleFooterIconsClick(item: string) {
      const hostname = window.location.hostname;
      WEBSITE_ANALYTICS_EVENTS;
      analyticsService.logActionEvent(
        WEBSITE_ANALYTICS_EVENTS.WEBSITE_FOOTER_SOCIAL_ICON_CLICKED,
        {
          hostname,
          social_medium: item,
        }
      );
    },
  },
});
</script>
