<template>
  <template v-if="packages">
    <div
      class="package section"
      id="package-section"
      v-show="isAnyActivePackage && isAnyValidStartDate && isAnyValidEndDate"
    >
      <!-- Blob -->
      <svg
        class="package__blob"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="417.136px"
        height="666.717px"
        viewBox="0 0 417.136 666.717"
        enable-background="new 0 0 417.136 666.717"
        xml:space="preserve"
      >
        <path
          fill="var(--mainColor2)"
          d="M417.136,63.353c-7.622,4.102-16.345,6.89-25.81,7.94c-25.544,2.836-49.438-7.722-62.413-25.377c-3.719-5.589-8.053-10.911-13.009-15.866c-38.557-38.558-99.88-40.447-137.193-3.135c-17.555,17.555-26.625,40.464-27.295,63.818c-1.004,17.984-9.131,36.717-24.029,51.616c-12.47,12.47-27.626,20.196-42.781,22.976c-21.517,1.917-41.909,10.684-57.712,26.486c-38.663,38.664-35.232,104.777,7.662,147.671c14.274,14.274,31.121,24.173,48.671,29.608c27.383,9.853,56.08,28.089,81.588,53.597c38.848,38.849,60.846,85.095,62.014,122.822l0.007,0.006c1.556,29.32,14.035,58.89,37.208,82.063c31.641,31.64,75.202,43.35,113.775,34.524c7.908-1.225,26.152-2.912,39.316,4.613V63.353z"
        />
      </svg>
      <!-- Container -->
      <div class="package__container container">
        <div class="package__title specialTitle">
          <h3 class="package__subTitle specialTitle__sub">PACKAGES</h3>
          <h1 class="package__mainTitle specialTitle__main">
            Check Our Latest Deals
          </h1>
        </div>
        <div class="package__packages">
          <!-- package -->
          <template v-for="(pack, index) in packages" :key="index">
            <div
              v-if="
                pack.active === true &&
                isValidStartDate(pack.start_date) &&
                isValidEndDate(pack.end_date)
              "
              :class="`package__package ${
                index === 0 ? 'package__package--latest' : ''
              }`"
              @click="onClickPackagesHandler(pack.name)"
            >
              <div>
                <img
                  class="package__packIcon"
                  :src="renderImage(pack.display_image_url)"
                  alt="package image"
                  @click="$emit('on:select', pack.display_image_url)"
                />
                <h3 class="package__packageName">{{ pack.name }}</h3>
                <p class="package__packageDesc">
                  {{ pack.description }}
                </p>
              </div>
              <p class="package__packagePrice">
                {{ pack.price }}
              </p>
            </div>
          </template>
        </div>
      </div>
    </div></template
  >
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";
import { Package } from "@/types";
import analyticsService from "@/analytics.service";
import { WEBSITE_ANALYTICS_EVENTS } from "@/constants/analyticsEvents";
import { getCurrencyFormatter } from "@/ag-flight-components/utils";
import { DateSchema } from "yup";

export default defineComponent({
  name: "MyPackages",
  props: {
    packages: {
      type: Array as PropType<Package[]>,
      required: true,
    },
  },
  emits: ["on:select"],
  computed: {
    isAnyValidStartDate() {
      const today = new Date();
      const ts = today.toISOString().split("T")[0];
      for (const item of this.packages) {
        if (ts >= item.start_date) {
          localStorage.setItem("isValidStartDate", "true");
          return true;
        }
      }

      localStorage.setItem("isValidStartDate", "false");
      return false;
    },
    isAnyValidEndDate() {
      const today = new Date();
      const ts = today.toISOString().split("T")[0];
      for (const item of this.packages) {
        if (ts <= item.end_date) {
          localStorage.setItem("isValidEndDate", "true");
          return true;
        }
      }

      localStorage.setItem("isValidEndDate", "false");
      return false;
    },
    isAnyActivePackage() {
      for (const item of this.packages) {
        if (
          item.active &&
          this.isValidEndDate(item.end_date) &&
          this.isValidStartDate(item.start_date)
        ) {
          localStorage.setItem("isActivePackage", "true");
          return true;
        }
      }

      localStorage.setItem("isActivePackage", "false");

      return false;
    },
  },
  methods: {
    renderImage(image: string | undefined) {
      return image ? image : "/assets/images/umrahPic.jpg";
    },
    onClickPackagesHandler(name: string) {
      let eventName = WEBSITE_ANALYTICS_EVENTS.WEBSITE_PACKAGE_CLICK;
      let eventData = name;

      analyticsService.logActionEvent(eventName, eventData);
    },
    formatCurrency(amount: string) {
      return getCurrencyFormatter().format(Number(amount));
    },
    isValidStartDate(start_date: string) {
      const today = new Date();
      const ts = today.toISOString().split("T")[0];
      if (ts >= start_date) {
        return true;
      }

      return false;
    },
    isValidEndDate(end_date: string) {
      const today = new Date();
      const ts = today.toISOString().split("T")[0];

      if (ts <= end_date) {
        return true;
      }

      return false;
    },
  },
});
</script>

<style></style>
