<template>
  <div
    class="contact section"
    id="contact-section"
    v-if="isValidWhatsAppNumber"
  >
    <div class="contact__container container">
      <!-- Title -->
      <div class="contact__title specialTitle">
        <h3 class="contact__subTitle specialTitle__sub">CONTACT US</h3>
        <h1 class="contact__mainTitle specialTitle__main">Get in Touch</h1>
      </div>
      <!-- Form -->
      <form class="contact__form" @submit.prevent="onSubmitMessageHandler">
        <div class="contact__formRow">
          <input
            required
            v-model="subject"
            type="text"
            name="subject"
            placeholder="Subject"
            class="contact__subject contact__textField"
          />
        </div>
        <textarea
          required
          v-model="message"
          cols="30"
          rows="3"
          name="message"
          placeholder="Message here..."
          class="contact__message contact__textField"
        ></textarea>
        <input
          type="submit"
          value="Send Message"
          class="contact__send specialLink"
        />
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { WebsiteConfig } from "@/ag-flight-components/types/WebsiteConfig";

export default defineComponent({
  name: "ContactUs",
  computed: {
    websiteConfig(): WebsiteConfig {
      return this.$store.getters.websiteConfig;
    },
    isValidWhatsAppNumber() {
      const isValid =
        this.websiteConfig?.contact_detail?.whatsapp_number || null;
      if (isValid === null || isValid === "") {
        return false;
      }
      return true;
    },
  },
  props: {
    phone: {
      type: String,
    },
  },
  data() {
    return {
      subject: "",
      message: "",
    };
  },
  methods: {
    onSubmitMessageHandler() {
      if (this.subject.trim().length > 0 && this.message.trim().length > 0) {
        const uri = `https://api.whatsapp.com/send?phone=${this.phone}&text=*${this.subject}* ${this.message}`;
        const encoded = encodeURI(uri);
        const link = document.createElement("a");

        link.href = encoded;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        alert("Please fill in both the subject and message.");
      }
    },
  },
});
</script>

<style></style>
