import { createStore, ModuleTree } from "vuex";
import flightModule from "./modules/Flight/vuex";
import flightBookingModule from "./modules/Booking/vuex";
import websiteConfigModule from "./modules/Website/vuex";

const modules: ModuleTree<any> = {
  flightModule,
  flightBookingModule,
  websiteConfigModule,
};
export default createStore({
  modules,
});
