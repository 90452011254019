import { GetterTree } from "vuex";
import { IWebsiteConfig } from "@/ag-flight-components/interfaces/IWebsite.interface";
import { WebsiteConfig } from "@/ag-flight-components/types/WebsiteConfig";

const getters: GetterTree<IWebsiteConfig, IWebsiteConfig> = {
  isWebsiteConfigLoading: (state): boolean => state.isLoading,
  isPageNotFound: (state): boolean => state.pageNotFound,
  websiteConfig: (state): WebsiteConfig | null => state.websiteConfig,
};

export default getters;
