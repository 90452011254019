import { MutationTree } from "vuex";

import { IFlightBooking } from "@/ag-flight-components/interfaces/IFlightBooking.interface";
import { FlightDetailsType } from "@/ag-flight-components/types";
import {
  Traveler,
  UpdateTravelerType,
} from "@/ag-flight-components/types/FlightBookingForm";

const mutations: MutationTree<IFlightBooking> = {
  setLoading(state, payload: boolean) {
    state.isLoading = payload;
  },
  setConfirmBookingLoading(state, payload: boolean) {
    state.confirmBookingLoading = payload;
  },
  setDownloadTicketLoading(state, payload: boolean) {
    state.downloadTicketLoading = payload;
  },
  setCancelBookingLoading(state, payload: boolean) {
    state.cancelBookingLoading = payload;
  },
  saveFlightDetails(state, payload: FlightDetailsType) {
    state.flightDetails = payload;
  },
  saveBookingStatus(state, payload: string) {
    state.bookingStatus = payload;
  },
  savePnrExpiry(state, payload: Date) {
    state.pnrExpiryDate = payload;
  },
  saveSupplierPnr(state, payload: string) {
    state.supplierPnr = payload;
  },
  saveTravelers(state, payload: Array<Traveler>) {
    state.travelers = payload;
  },
  updateTaveler(state, payload: UpdateTravelerType) {
    const updatedTraveler: any = state.travelers[payload.index];
    updatedTraveler[payload.key] = payload.value;
    state.travelers[payload.index] = updatedTraveler;
  },
  updateMobileNumber(state, payload: string) {
    state.mobileNumber = payload;
  },
  updateEmail(state, payload: string) {
    state.email = payload;
  },
  setVoidTicketLoading(state, payload: boolean) {
    state.voidTicketLoading = payload;
  },
};

export default mutations;
