<template>
  <nav-bar></nav-bar>
  <div class="page_container">
    <slot name="content"></slot>
  </div>
  <Footer></Footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import NavBar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

export default defineComponent({
  name: "LayoutComponent",
  components: {
    NavBar,
    Footer,
  },
});
</script>

<style scoped lang="scss">
.page_container {
  margin: 110px 200px;
}
</style>
