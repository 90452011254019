<template>
  <template v-if="services">
    <div class="services" id="services-section">
      <div class="services__container container">
        <div class="services__info">
          <h1 class="services__infoTitle specialTitle1">
            Anything you need, we{{ "’" }}ve got you covered
          </h1>
          <a
            href="#contact-section"
            class="services__infoLink specialLink"
            v-if="isValidWhatsAppNumber"
            >Get in Touch</a
          >
        </div>
        <div class="services__servs">
          <swiper
            :slidesPerView="'auto'"
            :space-between="30"
            :centered-slides="true"
            :navigation="true"
            direction="horizontal"
            :autoplay="{
              delay: 2500,
              disableOnInteraction: false,
            }"
            :modules="modules"
          >
            <swiper-slide
              class="services__serv"
              v-for="(service, index) in services"
              :key="index"
              @click="onClickServicesHandler(service.name)"
            >
              <img
                class="services__servIcon"
                :src="renderImage(service.display_image_url)"
                alt="service image"
                style="height: 105px; width=180px"
                @click="$emit('on:select', service.display_image_url)"
              />
              <span class="services__servTitle">
                {{ service.name }}
              </span>
              <span class="services__servDesc">
                {{ service.description }}
              </span>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </template>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Navigation } from "swiper/modules";
import { Service } from "@/types";
import analyticsService from "@/analytics.service";
import { WEBSITE_ANALYTICS_EVENTS } from "@/constants/analyticsEvents";

import { WebsiteConfig } from "@/ag-flight-components/types/WebsiteConfig";

import "swiper/css";
import "swiper/css/navigation";

export default defineComponent({
  name: "MyServices",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    services: {
      type: Array as PropType<Service[]>,
      required: true,
    },
  },
  emits: ["on:select"],
  computed: {
    modules() {
      if (this.services.length > 2) {
        return [Navigation, Autoplay];
      } else {
        return [Navigation];
      }
    },
    websiteConfig(): WebsiteConfig {
      return this.$store.getters.websiteConfig;
    },
    isValidWhatsAppNumber() {
      const isValid =
        this.websiteConfig?.contact_detail?.whatsapp_number || null;
      if (isValid === null) {
        return false;
      }
      return true;
    },
  },
  methods: {
    renderImage(image: string | undefined) {
      return image ? image : "/assets/images/umrahPic.jpg";
    },
    onClickServicesHandler(name: string) {
      let eventName = WEBSITE_ANALYTICS_EVENTS.WEBSITE_SERVICE_CLICK;
      let eventData = name;

      analyticsService.logActionEvent(eventName, eventData);
    },
  },
});
</script>

<style></style>
