<template>
  <div class="image-previewer" v-if="state" @click="onModalCloseHandler">
    <Transition name="bounce">
      <div class="wrapper" v-show="animationState">
        <div class="ip-close-icon" @click="onModalCloseHandler">&#x2715;</div>
        <img
          class="ip-image"
          :src="imageSrc"
          alt="image preview"
          loading="lazy"
        />
      </div>
    </Transition>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ImageLightBox",
  props: {
    state: {
      type: Boolean,
      required: true,
    },
    imageSrc: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      animationState: false,
    };
  },
  emits: ["update:state"],
  computed: {
    modalState: {
      get() {
        return this.state;
      },
      set(val: boolean) {
        this.$emit("update:state", val);
      },
    },
  },
  watch: {
    state(val: boolean) {
      if (val) {
        setTimeout(() => {
          this.animationState = true;
        }, 100);
      }
    },
  },
  methods: {
    onModalCloseHandler(): void {
      this.animationState = false;
      setTimeout(() => {
        this.modalState = false;
      }, 500);
    },
  },
});
</script>

<style lang="scss">
.image-previewer {
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 20px;
  display: grid;
  place-items: center;
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.5);

  .wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 600px;
    max-height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 8px;
      background-color: rgba(0, 0, 0, 0.5);
    }
    &::-webkit-scrollbar-thumb {
      background-color: white;
      border-radius: 8px;
    }

    .ip-image {
      width: 100%;
    }

    .ip-close-icon {
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: white;
      width: 25px;
      height: 25px;
      display: grid;
      place-items: center;
      border-radius: 4px;
      color: black;
      user-select: none;
      cursor: pointer;

      &:active {
        transform: scale(0.95);
      }
    }
  }
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
</style>
