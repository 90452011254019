import { ActionContext, ActionTree } from "vuex";
import { StatusCodes } from "http-status-codes";

import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import FlightService from "@/ag-flight-components/services/flight.service";
import AirportService from "@/ag-flight-components/services/airport.service";
import {
  getAirlineFilters,
  calculatePriceRangeFilters,
} from "@/ag-flight-components/utils/index";
import { flightStopsFilters } from "@/ag-flight-components/constants/flightStopFilters";
import { FLIGHT_ANALYTICS_EVENTS } from "@/constants/analyticsEvents";

import {
  FetchFlightsPayload,
  FlightPreBookingTokenType,
} from "@/ag-flight-components/types/Flights";
import { Place } from "@/ag-flight-components/types/Place";
import { IFlightSearch } from "@/ag-flight-components/interfaces/IFlightSearch.interface";
import analyticsService from "@/services/analytics.service";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";

const actions: ActionTree<IFlightSearch, IFlightSearch> = {
  // **** Fetch Airports ****
  async fetchAirports(
    context: ActionContext<IFlightSearch, IFlightSearch>,
    payload: string
  ) {
    const methodName = "actions.fetchAirports";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("loadingAirports", true);
      const fetchAirports = new AirportService();
      const response: IAGResponse<any> = await fetchAirports.get(payload);
      if (response.success && response.status === StatusCodes.OK) {
        const formattedAirports = response.data?.data?.map((item: Place) => {
          return {
            ...item,
            label: `${item.city} (${item.iata_code})`,
          };
        });
        context.commit("saveAirports", formattedAirports);
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
      } else {
        throw response;
      }
    } catch (err) {
      loggerService.logError(`${methodName}:`, err);
    } finally {
      context.commit("loadingAirports", false);
    }
  },

  // **** Fetch Flights ****
  async fetchFlights(
    context: ActionContext<IFlightSearch, IFlightSearch>,
    payload: FetchFlightsPayload
  ) {
    const methodName = "actions.fetchFlights";

    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("loadingFlights", true);
      const flightService = new FlightService();
      const response: IAGResponse<any> = await flightService.post(payload);
      if (response.success && response.status === StatusCodes.OK) {
        context.commit("saveFlights", response.data?.data);

        const filters = {
          airlines: getAirlineFilters(
            response.data.data.journey_legs[0].flight_options
          ),
          stops: flightStopsFilters,
          priceRange: calculatePriceRangeFilters(
            response.data.data.journey_legs[0].flight_options
          ),
          departureTime: ["00-06", "06-12", "12-18", "18-24"],
        };

        const selectedFlightFilters = {
          departureTime: ["00-06", "06-12", "12-18", "18-24"],
          airlines: filters.airlines.map((x) => x.value),
          stops: filters.stops.map((x) => x.value),
          priceRange: filters.priceRange,
          maxPriceRangeSlider: filters.priceRange[1],
        };

        context.commit("saveFilters", filters);
        context.commit("saveSelectedFlightFilters", selectedFlightFilters);

        // Analytics Event
        analyticsService.logActionEvent(
          FLIGHT_ANALYTICS_EVENTS.AG_FLIGHT_SEARCH_APPLIED,
          payload
        );
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
      } else {
        throw response;
      }
    } catch (error: any) {
      loggerService.logError(`${methodName}:`, error);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description = error?.error || "";
      notificationService.triggerNotification();
    } finally {
      context.commit("loadingFlights", false);
    }
  },

  // INITIATE BOOKING
  async initiateBooking(
    context: ActionContext<IFlightSearch, IFlightSearch>,
    {
      payload,
      successCallback,
    }: {
      payload: FlightPreBookingTokenType;
      successCallback: (booking_id: string) => void;
    }
  ) {
    const methodName = "actions.initiateBooking";

    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setLoading", true);
      const flightService = new FlightService();
      const response: IAGResponse<any> = await flightService.initiateBooking(
        payload
      );
      if (response.success && response.status === StatusCodes.OK) {
        const booking_id = response?.data?.data?.booking_id;
        successCallback(booking_id);
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
      } else {
        throw response;
      }
    } catch (error: any) {
      loggerService.logError(`${methodName}:`, error);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description = error?.error || "";
      notificationService.triggerNotification();
    } finally {
      context.commit("setLoading", false);
    }
  },
};
export default actions;
