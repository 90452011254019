import { ActionContext, ActionTree } from "vuex";
import { StatusCodes } from "http-status-codes";

import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import WebsiteService from "@/ag-flight-components/services/website.service";
import { IWebsiteConfig } from "@/ag-flight-components/interfaces/IWebsite.interface";

const actions: ActionTree<IWebsiteConfig, IWebsiteConfig> = {
  // **** Fetch Website Config ****
  async fetchWebsiteConfig(
    context: ActionContext<IWebsiteConfig, IWebsiteConfig>,
    payload: string
  ) {
    const methodName = "actions.fetchWebsiteConfig";

    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setPageNotFound", false);
      context.commit("loadingWebsiteConfig", true);
      const websiteConfigService = new WebsiteService();
      const response: IAGResponse<any> = await websiteConfigService.get(
        payload
      );
      if (response.success && response.status === StatusCodes.OK) {
        context.commit("saveWebsiteConfig", response.data?.data);
        document.title =
          response?.data?.data?.self_preferences?.display_name || "Aeroglobe";
        document.documentElement.style.setProperty(
          "--mainColor2",
          response?.data?.data?.self_preferences?.default_color
        );
        document.documentElement.style.setProperty(
          "--mainColor3",
          response?.data?.data?.self_preferences?.default_color
        );

        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
      } else {
        context.commit("setPageNotFound", true);
        throw response;
      }
    } catch (err) {
      context.commit("setPageNotFound", true);
      loggerService.logError(`${methodName}:`, err);
    } finally {
      context.commit("loadingWebsiteConfig", false);
    }
  },
};
export default actions;
