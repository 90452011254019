import { GetterTree } from "vuex";

import { IFlightBooking } from "@/ag-flight-components/interfaces/IFlightBooking.interface";
import { Traveler } from "@/ag-flight-components/types/FlightBookingForm";

const getters: GetterTree<IFlightBooking, IFlightBooking> = {
  isFlightBookingLoading: (state): boolean => state.isLoading,
  isFlightConfirmBookingLoading: (state): boolean =>
    state.confirmBookingLoading,
  flightTravelers: (state): Array<Traveler> => state.travelers,
  flightBookingStatus: (state): string => state.bookingStatus,
  flightDetails: (state) => state.flightDetails,
  isFlightCancelBookingLoading: (state) => state.cancelBookingLoading,
  isDownloadTicketLoading: (state) => state.downloadTicketLoading,
  isVoidFlightTicketLoading: (state) => state.voidTicketLoading,
};

export default getters;
