<template>
  <div class="about section" id="about-section">
    <!-- Blobs -->
    <svg
      class="about__blob1"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="278.622px"
      height="966.047px"
      viewBox="0 0 278.622 966.047"
      enable-background="new 0 0 278.622 966.047"
      xml:space="preserve"
    >
      <path
        fill="var(--mainColor2)"
        d="M193.534,304.497c-26.342-10.238-48.679-30.879-60.368-59.184c-9.782-23.692-10.493-48.843-3.759-71.565	c12.614-31.266,14.111-64.306,1.712-94.331C111.109,30.96,59.922,2.469,0,0v966.047c17.6-13.917,30.572-26.618,40.339-35.22	c17.216-15.161,31.873-32.933,42.274-52.494c25.301-47.588,14.234-100.309-21.019-139.549l0.018-0.016	c-18.687-21.113-24.229-53.311-11.644-86.607c14.133-37.393,47.234-65.893,83.151-75.519c11.089-2.501,22.22-5.998,33.249-10.552	c85.8-35.431,132.817-120.934,103.544-191.824C256.139,340.917,228.197,316.897,193.534,304.497z"
      />
    </svg>
    <svg
      class="about__blob2"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="336.167px"
      height="858.829px"
      viewBox="0 0 336.167 858.829"
      enable-background="new 0 0 336.167 858.829"
      xml:space="preserve"
    >
      <path
        fill="var(--mainColor2)"
        d="M336.167,0c-25.067,4.489-48.943,15.99-68.604,34.755c-26.102,24.914-40.056,57.895-41.82,91.808	c-2.068,26.099-14.511,53.043-36.664,74.188c-18.541,17.697-40.826,28.412-62.941,31.938c-31.33,2.057-61.257,14.105-84.755,36.534	c-57.488,54.871-54.741,151.055,6.134,214.833c20.258,21.225,44.404,36.178,69.721,44.672	c39.456,15.242,80.537,42.711,116.739,80.64c55.134,57.764,85.533,125.708,85.952,180.567l0.012,0.009	c0.691,23.269,6.114,46.751,16.228,68.886V0z"
      />
    </svg>
    <!-- Container -->
    <div class="about__container container">
      <img
        v-if="imageUrl && (title.length || description.length)"
        :src="imageUrl"
        alt="about-us-image"
        style="width: 626.873px; height: 375.345px"
      />
      <svg
        v-else
        class="about__illustration"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="626.873px"
        height="375.345px"
        viewBox="0 0 626.873 375.345"
        enable-background="new 0 0 626.873 375.345"
        xml:space="preserve"
      >
        <g opacity="0.3">
          <g>
            <g>
              <g>
                <path
                  fill="#D8ECF3"
                  d="M625.443,191.518c0,4.72-3.861,8.582-8.58,8.582h-51.489c-4.72,0-8.581-3.862-8.581-8.582v-13.76
                                        c0-4.721,3.861-8.583,8.581-8.583h51.489c4.719,0,8.58,3.862,8.58,8.583V191.518z"
                />
              </g>
            </g>
            <g>
              <path
                fill="#B9D0D6"
                d="M625.443,192.974c0,3.919-3.594,7.126-7.984,7.126h-47.912c-4.393,0-7.986-3.207-7.986-7.126v-11.427
                                    c0-3.919,3.594-7.125,7.986-7.125h47.912c4.391,0,7.984,3.207,7.984,7.125V192.974z"
              />
            </g>
            <g>
              <path
                fill="#152054"
                d="M555.363,191.516l-0.002-13.758c0.007-5.518,4.496-10.007,10.013-10.012l0,0l51.489,0
                                    c5.515,0.006,10.006,4.495,10.01,10.013l0,0v13.758c-0.004,5.518-4.495,10.008-10.01,10.012l0,0l-51.489,0.002
                                    C559.857,201.523,555.368,197.033,555.363,191.516L555.363,191.516z M565.374,170.606c-3.923,0.007-7.146,3.227-7.151,7.152l0,0
                                    v13.758c0.006,3.926,3.229,7.146,7.151,7.154l0,0l51.489-0.002c3.922-0.006,7.143-3.227,7.15-7.152l0,0v-13.758
                                    c-0.008-3.925-3.229-7.145-7.15-7.152l0,0H565.374L565.374,170.606z"
              />
            </g>
          </g>
          <g>
            <g>
              <g>
                <path
                  fill="#D8ECF3"
                  d="M592.885,160.588c0,4.72-3.863,8.582-8.582,8.582h-51.488c-4.719,0-8.58-3.862-8.58-8.582v-13.76
                                        c0-4.719,3.861-8.581,8.58-8.581h51.488c4.719,0,8.582,3.861,8.582,8.581V160.588z"
                />
              </g>
            </g>
            <g>
              <path
                fill="#B9D0D6"
                d="M592.885,162.045c0,3.919-3.594,7.125-7.986,7.125h-47.912c-4.393,0-7.984-3.206-7.984-7.125v-11.426
                                    c0-3.92,3.592-7.127,7.984-7.127h47.912c4.393,0,7.986,3.207,7.986,7.127V162.045z"
              />
            </g>
            <g>
              <path
                fill="#152054"
                d="M522.803,160.587v-13.759c0.006-5.517,4.496-10.007,10.012-10.011l0,0l51.488-0.001
                                    c5.516,0.005,10.006,4.496,10.012,10.013l0,0v13.759c-0.006,5.517-4.496,10.007-10.012,10.013l0,0h-51.488
                                    C527.299,170.594,522.809,166.104,522.803,160.587L522.803,160.587z M532.814,139.677c-3.923,0.007-7.145,3.229-7.152,7.151l0,0
                                    v13.759c0.008,3.925,3.229,7.146,7.152,7.154l0,0l51.488-0.002c3.922-0.006,7.143-3.227,7.151-7.151l0,0v-13.759
                                    c-0.009-3.923-3.229-7.145-7.151-7.152l0,0L532.814,139.677L532.814,139.677z"
              />
            </g>
          </g>
        </g>
        <g opacity="0.3">
          <g>
            <g>
              <g>
                <path
                  fill="#D8ECF3"
                  d="M70.082,213.023c0,4.719-3.862,8.582-8.58,8.582H10.013c-4.72,0-8.582-3.863-8.582-8.582v-13.761
                                        c0-4.72,3.862-8.581,8.582-8.581h51.489c4.718,0,8.58,3.861,8.58,8.581V213.023z"
                />
              </g>
            </g>
            <g>
              <path
                fill="#B9D0D6"
                d="M70.082,214.479c0,3.918-3.592,7.127-7.985,7.127H14.185c-4.394,0-7.985-3.209-7.985-7.127v-11.428
                                    c0-3.918,3.592-7.125,7.985-7.125h47.913c4.393,0,7.985,3.207,7.985,7.125V214.479z"
              />
            </g>
            <g>
              <path
                fill="#152054"
                d="M0.001,213.021L0,199.263c0.006-5.517,4.496-10.007,10.013-10.011l0,0l51.489-0.001
                                    c5.514,0.005,10.005,4.495,10.011,10.012l0,0v13.759c-0.005,5.518-4.497,10.008-10.011,10.011l0,0l-51.489,0.003
                                    C4.496,223.029,0.006,218.539,0.001,213.021L0.001,213.021z M10.013,192.111c-3.923,0.006-7.145,3.228-7.152,7.151l0,0v13.759
                                    c0.007,3.924,3.229,7.146,7.152,7.153l0,0l51.489-0.003c3.921-0.004,7.142-3.227,7.15-7.15l0,0v-13.759
                                    c-0.008-3.924-3.229-7.146-7.15-7.151l0,0H10.013L10.013,192.111z"
              />
            </g>
          </g>
          <g>
            <g>
              <g>
                <path
                  fill="#D8ECF3"
                  d="M101.636,182.094c0,4.718-3.862,8.582-8.581,8.582H41.568c-4.721,0-8.582-3.863-8.582-8.582v-13.76
                                        c0-4.719,3.861-8.581,8.582-8.581h51.487c4.719,0,8.581,3.861,8.581,8.581V182.094z"
                />
              </g>
            </g>
            <g>
              <path
                fill="#B9D0D6"
                d="M101.636,183.55c0,3.92-3.593,7.126-7.984,7.126H45.739c-4.393,0-7.986-3.206-7.986-7.126v-11.426
                                    c0-3.92,3.593-7.127,7.986-7.127h47.913c4.392,0,7.984,3.207,7.984,7.127V183.55z"
              />
            </g>
            <g>
              <path
                fill="#152054"
                d="M31.556,182.093l-0.002-13.759c0.006-5.517,4.496-10.006,10.014-10.012l0,0l51.487-0.001
                                    c5.516,0.006,10.006,4.496,10.011,10.013l0,0v13.759c-0.005,5.518-4.495,10.007-10.011,10.013l0,0H41.568
                                    C36.05,192.1,31.56,187.61,31.556,182.093L31.556,182.093z M41.568,161.183c-3.925,0.006-7.146,3.227-7.152,7.151l0,0v13.759
                                    c0.006,3.925,3.228,7.146,7.152,7.153l0,0l51.487-0.002c3.922-0.006,7.143-3.227,7.15-7.151l0,0v-13.759
                                    c-0.007-3.925-3.229-7.146-7.15-7.152l0,0L41.568,161.183L41.568,161.183z"
              />
            </g>
          </g>
        </g>
        <g opacity="0.3">
          <g>
            <g>
              <g>
                <path
                  fill="#D8ECF3"
                  d="M162.219,54.703c0,4.719-3.861,8.582-8.582,8.582H102.15c-4.719,0-8.58-3.863-8.58-8.582v-13.76
                                        c0-4.721,3.861-8.582,8.58-8.582h51.488c4.721,0,8.582,3.861,8.582,8.582V54.703z"
                />
              </g>
            </g>
            <g>
              <path
                fill="#B9D0D6"
                d="M162.219,56.157c0,3.919-3.593,7.127-7.984,7.127h-47.913c-4.392,0-7.985-3.208-7.985-7.127V44.732
                                    c0-3.921,3.594-7.128,7.985-7.128h47.913c4.391,0,7.984,3.207,7.984,7.128V56.157z"
              />
            </g>
            <g>
              <path
                fill="#152054"
                d="M92.14,54.701l-0.002-13.759c0.006-5.517,4.497-10.007,10.013-10.011l0,0l51.488-0.003
                                    c5.515,0.006,10.007,4.497,10.012,10.014l0,0v13.759c-0.005,5.516-4.497,10.006-10.012,10.01l0,0l-51.488,0.002
                                    C96.634,64.707,92.144,60.217,92.14,54.701L92.14,54.701z M102.15,33.791c-3.924,0.008-7.146,3.228-7.151,7.151l0,0v13.759
                                    c0.006,3.924,3.227,7.146,7.151,7.153l0,0l51.488-0.002c3.923-0.005,7.143-3.227,7.151-7.15l0,0V40.942
                                    c-0.008-3.923-3.229-7.144-7.151-7.152l0,0L102.15,33.791L102.15,33.791z"
              />
            </g>
          </g>
          <g>
            <g>
              <g>
                <path
                  fill="#D8ECF3"
                  d="M131.559,23.773c0,4.72-3.862,8.582-8.582,8.582H71.49c-4.72,0-8.58-3.862-8.58-8.582v-13.76
                                        c0-4.72,3.86-8.581,8.58-8.581h51.488c4.719,0,8.582,3.861,8.582,8.581V23.773z"
                />
              </g>
            </g>
            <g>
              <path
                fill="#B9D0D6"
                d="M131.559,25.229c0,3.92-3.593,7.126-7.986,7.126H75.661c-4.391,0-7.984-3.207-7.984-7.126V13.802
                                    c0-3.919,3.593-7.126,7.984-7.126h47.913c4.393,0,7.986,3.207,7.986,7.126V25.229z"
              />
            </g>
            <g>
              <path
                fill="#152054"
                d="M61.479,23.772l-0.002-13.759C61.483,4.497,65.974,0.006,71.49,0.001l0,0L122.978,0
                                    c5.516,0.006,10.006,4.497,10.011,10.014l0,0v13.759c-0.005,5.517-4.496,10.005-10.011,10.012l0,0L71.49,33.785
                                    C65.974,33.777,61.483,29.289,61.479,23.772L61.479,23.772z M71.49,2.862c-3.924,0.007-7.145,3.227-7.151,7.152l0,0v13.759
                                    c0.006,3.924,3.227,7.146,7.151,7.152l0,0l51.488-0.002c3.922-0.004,7.143-3.227,7.151-7.15l0,0V10.014
                                    c-0.008-3.925-3.229-7.145-7.151-7.152l0,0L71.49,2.862L71.49,2.862z"
              />
            </g>
          </g>
          <g>
            <g>
              <g>
                <path
                  fill="#D8ECF3"
                  d="M200.448,23.773c0,4.72-3.861,8.582-8.582,8.582h-51.489c-4.719,0-8.581-3.862-8.581-8.582v-13.76
                                        c0-4.72,3.862-8.581,8.581-8.581h51.489c4.72,0,8.582,3.861,8.582,8.581V23.773z"
                />
              </g>
            </g>
            <g>
              <path
                fill="#B9D0D6"
                d="M200.448,25.229c0,3.92-3.594,7.126-7.986,7.126h-47.913c-4.391,0-7.984-3.207-7.984-7.126V13.802
                                    c0-3.919,3.593-7.126,7.984-7.126h47.913c4.392,0,7.986,3.207,7.986,7.126V25.229z"
              />
            </g>
            <g>
              <path
                fill="#152054"
                d="M130.367,23.772l0-13.759c0.004-5.517,4.494-10.008,10.011-10.013l0,0L191.867,0
                                    c5.516,0.006,10.006,4.497,10.011,10.014l0,0v13.759c-0.005,5.517-4.495,10.005-10.011,10.012l0,0l-51.489,0.001
                                    C134.861,33.777,130.371,29.289,130.367,23.772L130.367,23.772z M140.378,2.862c-3.923,0.007-7.144,3.227-7.15,7.152l0,0v13.759
                                    c0.006,3.924,3.227,7.146,7.15,7.152l0,0l51.489-0.002c3.922-0.004,7.144-3.227,7.151-7.15l0,0V10.014
                                    c-0.008-3.925-3.229-7.145-7.151-7.152l0,0L140.378,2.862L140.378,2.862z"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle fill="#F9E35D" cx="173.806" cy="22.897" r="13.194" />
            <path
              fill="#152054"
              d="M158.657,22.897c0-8.369,6.782-15.148,15.149-15.148l0,0c8.368,0,15.147,6.78,15.147,15.148l0,0
                                c0,8.366-6.779,15.147-15.147,15.148l0,0C165.439,38.044,158.657,31.263,158.657,22.897L158.657,22.897z M162.566,22.897
                                c0.009,6.207,5.031,11.228,11.24,11.238l0,0c6.207-0.01,11.229-5.031,11.24-11.238l0,0c-0.011-6.208-5.033-11.23-11.24-11.239
                                l0,0C167.598,11.667,162.576,16.689,162.566,22.897L162.566,22.897z"
            />
          </g>
          <g>
            <g>
              <circle fill="#F9E35D" cx="212.571" cy="105.968" r="29.481" />
            </g>
            <g>
              <path
                fill="#152054"
                d="M181.134,105.968c0.002-17.365,14.076-31.438,31.436-31.439l0,0c17.364,0,31.437,14.074,31.437,31.439
                                    l0,0c0,17.361-14.073,31.433-31.437,31.435l0,0C195.21,137.4,181.136,123.329,181.134,105.968L181.134,105.968z
                                    M185.044,105.968c0.027,15.199,12.326,27.498,27.526,27.523l0,0c15.203-0.025,27.5-12.325,27.527-27.523l0,0
                                    c-0.027-15.204-12.324-27.503-27.527-27.528l0,0C197.37,78.465,185.071,90.764,185.044,105.968L185.044,105.968z"
              />
            </g>
          </g>
          <g>
            <g>
              <polygon
                fill="#D6DAE7"
                points="47.424,119.28 56.19,114.965 127.777,260.335 119.008,264.653 47.424,119.28 				"
              />
            </g>
            <g>
              <polygon
                fill="#D6DAE7"
                points="68.923,119.28 77.691,114.965 149.275,260.335 140.508,264.653 68.923,119.28 				"
              />
            </g>
            <g>
              <g>
                <path
                  fill="#152054"
                  d="M45.67,120.145l1.754-0.864l0.863,1.752l-0.863-1.752L45.67,120.145l-0.865-1.754l12.273-6.042
                                        l73.315,148.878l-12.277,6.045L45.67,120.145L45.67,120.145z M119.898,262.033l5.261-2.59L55.299,117.58l-5.259,2.591
                                        L119.898,262.033L119.898,262.033z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  fill="#152054"
                  d="M67.168,120.145l1.755-0.864l0.863,1.752l-0.863-1.752L67.168,120.145l-0.862-1.754l12.273-6.046
                                        l73.314,148.882l-12.276,6.045L67.168,120.145L67.168,120.145z M141.397,262.033l5.259-2.59L76.798,117.58l-5.261,2.591
                                        L141.397,262.033L141.397,262.033z"
                />
              </g>
            </g>
          </g>
          <g>
            <g>
              <polygon
                fill="#D6DAE7"
                points="59.924,136.739 54.892,128.362 193.785,44.898 198.819,53.277 59.924,136.739 				"
              />
            </g>
            <g>
              <polygon
                fill="#D6DAE7"
                points="58.131,115.314 53.098,106.938 191.993,23.475 197.025,31.854 58.131,115.314 				"
              />
            </g>
            <g>
              <g>
                <path
                  fill="#152054"
                  d="M60.931,138.415l-1.005-1.676l1.672-1.006l-1.672,1.006L60.931,138.415l-1.675,1.006l-7.045-11.727
                                        l142.243-85.476l7.048,11.73L60.931,138.415L60.931,138.415z M196.134,52.61l-3.02-5.027l-135.542,81.45l3.019,5.025
                                        L196.134,52.61L196.134,52.61z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  fill="#152054"
                  d="M59.137,116.991l-1.005-1.677l1.674-1.005l-1.674,1.005L59.137,116.991l-1.676,1.006l-7.048-11.728
                                        l142.249-85.475l7.045,11.729L59.137,116.991L59.137,116.991z M194.341,31.186l-3.019-5.027L55.778,107.607l3.021,5.028
                                        L194.341,31.186L194.341,31.186z"
                />
              </g>
            </g>
          </g>
          <g>
            <path
              fill="#D6DAE7"
              d="M86.256,119.812c0,11.607-9.406,21.011-21.012,21.011s-21.012-9.403-21.012-21.011
                                c0-11.607,9.406-21.011,21.012-21.011S86.256,108.205,86.256,119.812z"
            />
          </g>
          <circle fill="#152054" cx="65.246" cy="119.812" r="3.298" />
          <g>
            <path
              fill="#152054"
              d="M53.761,119.812c0.003-6.345,5.14-11.481,11.484-11.484l0,0c6.344,0.002,11.482,5.139,11.482,11.484l0,0
                                c0,6.341-5.139,11.48-11.482,11.485l0,0C58.901,131.292,53.764,126.152,53.761,119.812L53.761,119.812z M57.67,119.812
                                c0.008,4.183,3.39,7.564,7.575,7.572l0,0c4.184-0.007,7.565-3.389,7.573-7.572l0,0c-0.008-4.185-3.39-7.568-7.573-7.575l0,0
                                C61.06,112.243,57.678,115.627,57.67,119.812L57.67,119.812z"
            />
          </g>
          <g>
            <path
              fill="#152054"
              d="M42.277,119.812c0.002-12.688,10.282-22.965,22.968-22.966l0,0c12.688,0.001,22.965,10.279,22.966,22.966
                                l0,0c-0.001,12.685-10.278,22.965-22.966,22.965l0,0C52.559,142.777,42.279,132.497,42.277,119.812L42.277,119.812z
                                M46.186,119.812c0.021,10.525,8.532,19.037,19.059,19.055l0,0c10.526-0.018,19.039-8.529,19.057-19.055l0,0
                                c-0.018-10.529-8.531-19.039-19.057-19.057l0,0C54.718,100.773,46.208,109.282,46.186,119.812L46.186,119.812z"
            />
          </g>
          <path
            fill="#D6DAE7"
            d="M261.012,78.351c-2.446-5.311-7.199-8.826-12.519-9.91c-17.098-4.237-32.186-15.905-40.126-33.149
                            c-1.19-2.578-2.164-5.203-2.961-7.845l-0.111,0.03c-0.204-0.702-0.444-1.401-0.76-2.084c-3.239-7.036-11.246-10.359-18.417-7.903
                            l-0.035-0.07l-22.819,10.507c-7.505,3.456-10.785,12.34-7.332,19.844c0.318,0.688,0.697,1.33,1.1,1.946l-0.02,0.013
                            c1.494,2.323,2.851,4.77,4.039,7.349c7.824,16.994,7.021,35.741-0.559,51.35c-3.015,4.863-3.61,11.097-1.039,16.686
                            c4.075,8.851,14.555,12.722,23.406,8.646c0.146-0.068,0.288-0.141,0.429-0.211l0.007,0.01l70.027-32.242l-0.012-0.033
                            C261.483,96.918,264.939,86.88,261.012,78.351z"
          />
          <path
            fill="#F8FCFD"
            d="M253.309,101.283c8.174-4.365,11.63-14.403,7.703-22.933c-2.446-5.311-7.199-8.826-12.519-9.91
                            c-17.098-4.237-32.186-15.905-40.126-33.149c-1.19-2.578-2.164-5.203-2.961-7.845l-0.111,0.03
                            c-0.204-0.702-0.444-1.401-0.76-2.084c-2.788-6.055-9.106-9.348-15.383-8.589l-10.04,4.625
                            c-8.883,4.091-12.769,14.607-8.678,23.488c0.377,0.816,0.823,1.575,1.301,2.305l-0.023,0.016c1.769,2.75,3.375,5.646,4.781,8.701
                            c9.262,20.117,8.312,42.31-0.663,60.786c-3.361,5.426-4.179,12.286-1.718,18.594c2.908,0.228,5.911-0.252,8.748-1.559
                            c0.146-0.068,0.288-0.141,0.429-0.211l0.007,0.01l70.027-32.242L253.309,101.283z"
          />
          <g>
            <path
              fill="#152054"
              d="M157.677,125.93c-1.22-2.651-1.799-5.44-1.799-8.181l0,0c0-3.639,1.017-7.193,2.9-10.267l0,0
                                c3.798-7.863,5.823-16.544,5.823-25.372l0,0c0-8.112-1.708-16.353-5.328-24.214l0,0c-1.147-2.494-2.458-4.859-3.906-7.11l0,0
                                l-1.041-1.62l0.084-0.058c-0.087-0.169-0.172-0.344-0.256-0.521l0,0c-1.053-2.289-1.554-4.699-1.553-7.067l0,0
                                c-0.003-6.395,3.653-12.517,9.843-15.371l0,0l24.559-11.308l0.154,0.317c1.257-0.29,2.524-0.43,3.779-0.43l0,0
                                c6.397-0.002,12.52,3.653,15.374,9.842l0,0c0.084,0.183,0.162,0.364,0.236,0.547l0,0l0.183-0.051l0.549,1.815
                                c0.772,2.563,1.713,5.1,2.863,7.592l0,0c7.679,16.673,22.248,27.946,38.78,32.06l0,0c5.888,1.209,11.156,5.116,13.865,11l0,0
                                l-1.775,0.816l-1.775,0.817c-2.178-4.725-6.396-7.844-11.135-8.813l0,0l-0.045-0.01l-0.035-0.008
                                c-17.645-4.369-33.233-16.424-41.43-34.225l0,0c-0.959-2.079-1.781-4.182-2.486-6.295l0,0l-0.153,0.04l-0.535-1.833
                                c-0.186-0.642-0.396-1.246-0.658-1.812l0,0c-2.193-4.767-6.9-7.568-11.822-7.573l0,0c-1.4,0.002-2.808,0.225-4.182,0.7l0,0
                                l-0.653,0.226L164.081,29.7c-4.766,2.193-7.566,6.901-7.569,11.82l0,0c0,1.819,0.382,3.661,1.196,5.43l0,0
                                c0.264,0.574,0.591,1.131,0.957,1.692l0,0l1.066,1.621l-0.06,0.04c1.144,1.905,2.201,3.889,3.155,5.958l0,0
                                c3.86,8.38,5.687,17.186,5.687,25.85l0,0c0,9.463-2.182,18.757-6.264,27.171l0,0l-0.042,0.087l-0.053,0.088
                                c-1.536,2.477-2.366,5.356-2.366,8.292l0,0c0,2.192,0.461,4.415,1.442,6.546l0,0c2.643,5.745,8.321,9.128,14.259,9.128l0,0
                                c2.195,0,4.421-0.462,6.555-1.442l0,0c0.088-0.039,0.211-0.102,0.375-0.185l0,0l1.574-0.781l0.036,0.054l67.878-31.255
                                l0.48-0.257c5.23-2.789,8.288-8.204,8.288-13.845l0,0c0-2.194-0.46-4.417-1.439-6.546l0,0l1.775-0.817l1.775-0.816
                                c1.221,2.646,1.799,5.438,1.799,8.179l0,0c0,6.472-3.22,12.696-8.813,16.375l0,0l0.088,0.207l-72.053,33.178
                                c-0.042,0.02-0.088,0.042-0.135,0.063l0,0c-0.063,0.029-0.126,0.058-0.19,0.086l0,0l-0.923,0.426l-0.021-0.032
                                c-2.304,0.89-4.695,1.318-7.051,1.318l0,0C168.076,137.333,160.982,133.104,157.677,125.93L157.677,125.93z"
            />
          </g>
          <g>
            <path
              fill="#D6DAE7"
              d="M185.973,274.795c0-11.736-9.675-21.337-21.5-21.337H85.802c-11.825,0-21.5,9.601-21.5,21.337
                                c0,0.055,0.003,0.107,0.003,0.162h121.664C185.969,274.902,185.973,274.85,185.973,274.795z"
            />
          </g>
          <g>
            <path
              fill="#D6DAE7"
              d="M159.342,253.381c0-6.598-5.439-11.996-12.088-11.996h-44.233c-6.648,0-12.089,5.398-12.089,11.996
                                c0,0.031,0.002,0.061,0.002,0.092h68.406C159.34,253.441,159.342,253.412,159.342,253.381z"
            />
          </g>
          <path
            fill="#F8FCFD"
            d="M65.839,269.01h77.145c7.069,0,12.857-5.276,12.857-11.727v-3.825H85.802
                            c-9.794,0-18.108,6.588-20.677,15.519C65.363,268.99,65.598,269.01,65.839,269.01z"
          />
          <path
            fill="#F8FCFD"
            d="M91.796,250.131h43.375c3.976,0,7.229-2.969,7.229-6.596v-2.15h-39.379
                            c-5.506,0-10.181,3.704-11.627,8.726C91.529,250.118,91.662,250.131,91.796,250.131z"
          />
          <path
            fill="#FFFFFF"
            d="M85.802,253.458c-7.45,0-14.044,3.812-17.907,9.567h15.625c5.711,0,10.497-4.143,11.518-9.567H85.802z"
          />
          <path
            fill="#FFFFFF"
            d="M103.021,241.385c-4.189,0-7.896,2.144-10.068,5.381h8.785c3.212,0,5.902-2.329,6.476-5.381H103.021z"
          />
          <g>
            <g>
              <path
                fill="none"
                stroke="#152054"
                stroke-width="4"
                d="M185.973,274.795c0-11.736-9.675-21.337-21.5-21.337H85.802
                                    c-11.825,0-21.5,9.601-21.5,21.337c0,0.055,0.003,0.107,0.003,0.162h121.664C185.969,274.902,185.973,274.85,185.973,274.795z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                fill="none"
                stroke="#152054"
                stroke-width="4"
                d="M159.342,253.381c0-6.598-5.439-11.996-12.088-11.996h-44.233
                                    c-6.648,0-12.089,5.398-12.089,11.996c0,0.031,0.002,0.061,0.002,0.092h68.406C159.34,253.441,159.342,253.412,159.342,253.381z
                                    "
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <g>
                <path
                  fill="#A2DB69"
                  d="M533.738,201.18c-0.802-1.783-2.36-2.979-4.125-3.396c-2.088-0.562-5.504-0.746-6.977-1.719
                                        c-1.789-1.181-0.611-5.48-0.611-5.48s-0.008-0.016-0.014-0.024c0.252-1.01,0.182-2.109-0.279-3.134
                                        c-0.489-1.088-1.332-1.904-2.33-2.378c-0.005-0.009-0.008-0.021-0.012-0.032c-0.238-0.093-0.459-0.165-0.67-0.229
                                        c-0.056-0.015-0.108-0.036-0.164-0.05c-2.068-0.574-2.95,0.179-6.352,0.268c-2.887,0.074-4.123-3.117-4.555-4.687
                                        c-0.041-0.392-0.142-0.78-0.311-1.157c-0.73-1.623-2.461-2.476-4.137-2.167c-0.049-0.009-0.1-0.023-0.138-0.027
                                        c-8.984,2.023-11.835,0.027-15.642,0.614c-3.807,0.584-4.633,0.956-5.334,2.927c-0.703,1.971,3.354,3.191,5.17,5.318
                                        c1.44,1.687,1.722,5.131,1.775,6.493c-0.041,0.601,0.06,1.219,0.324,1.808c0.588,1.312,1.832,2.122,3.174,2.223
                                        c1.695,0.282,4.689-1.284,6.012-0.804c1.384,0.503,2.418,1.627,0.635,4.578c-2.21,3.178-2.988,5.646-2.988,5.646l0.014,0.016
                                        c-0.423,1.145-0.417,2.449,0.125,3.651c0.493,1.102,1.354,1.923,2.371,2.394c0.988,0.518,2.639,0.373,4.359,2.962
                                        c1.535,2.314-0.578,5.707-1.543,7.041c-1.498,1.722-1.954,4.22-0.957,6.439c1.366,3.039,4.938,4.396,7.979,3.029
                                        c0.09-0.04,0.172-0.089,0.256-0.131c0.002,0,0.004,0.001,0.008,0.001c0.02-0.012,0.042-0.024,0.063-0.037
                                        c0.209-0.109,0.408-0.226,0.598-0.354c7.432-4.303,10.233-1.065,10.233-1.065c5.478-2.461,5.575-2.902,6.476-6.351
                                        c1.545-5.059,5.354-12.97,6.922-16.138C534.338,205.531,534.662,203.234,533.738,201.18z"
                />
                <path
                  fill="#80C46A"
                  d="M529.613,197.783c-2.088-0.562-5.504-0.746-6.977-1.719c-1.789-1.181-0.611-5.48-0.611-5.48
                                        s-0.008-0.016-0.014-0.024c0.252-1.01,0.182-2.109-0.279-3.134c-0.489-1.088-1.332-1.904-2.33-2.378
                                        c-0.005-0.009-0.008-0.021-0.012-0.032c-0.238-0.093-0.459-0.165-0.67-0.229c-0.056-0.015-0.108-0.036-0.164-0.05
                                        c-2.068-0.574-2.95,0.179-6.352,0.268c-2.887,0.074-4.123-3.117-4.555-4.687c-0.041-0.392-0.142-0.78-0.311-1.157
                                        c-0.322-0.717-0.84-1.282-1.459-1.664c-2.526,3.539-8.932,5.875-14.774,3.932c-7.782-2.591-7.265-0.264-5.973-0.137
                                        c1.292,0.124,5.784,3.84,4.689,7.685c-1.094,3.847,7.405-1.389,8.999,0.19c1.592,1.58-0.004,3.53,3.203,1.826
                                        c3.203-1.706,5.141-1.518,1.567,3.883c-3.571,5.4,3.36,2.371,6.489,1.673c3.127-0.7,0.612,4.311-7.647,6.349
                                        c-8.261,2.037,3.596,1.914,11.008,3.877c7.41,1.96-1.312,2.971-5.319,4.066c-4.008,1.098-2.013,3.375,0.541,2.58
                                        c2.555-0.795,8.871,0.691,6.857,4.064c-2.012,3.376-9.816,3.09-10.722,5.792c-0.902,2.7,1.882,2.417,10.778,1.597
                                        c6.289-0.583,6.55,2.207,6.164,3.892c3.474-1.7,3.659-2.448,4.43-5.4c1.545-5.059,5.354-12.97,6.922-16.138
                                        c1.244-1.695,1.568-3.992,0.645-6.047C532.937,199.396,531.378,198.2,529.613,197.783z"
                />
                <path
                  fill="#A2DB69"
                  d="M529.355,203.504c0.839,1.865-1.725,2.134-3.268,4.115c-2.967,3.803-4.221,3.961-5.059,2.096
                                        c-0.838-1.862,0.369-6.695,2.66-7.726C525.98,200.961,528.516,201.637,529.355,203.504z"
                />
                <path
                  fill="#A2DB69"
                  d="M518.808,189.875c-0.091,1.652-2.032,0.896-3.878,1.747c-3.548,1.634-4.508,1.281-4.418-0.372
                                        c0.09-1.652,2.754-4.679,4.783-4.568S518.898,188.222,518.808,189.875z"
                />
                <path
                  fill="#4AA957"
                  d="M513.354,199.291c-1.959-2.943-4.062-5.42-6.085-7.461c-0.367-0.86-0.865-2.578-0.129-4.57
                                        c0.93-2.514-2.688-0.846-2.994,1.677c-2.089-1.774-3.938-3.043-5.236-3.854c-0.115-0.568-0.135-1.301,0.162-2.104
                                        c0.594-1.605-1.734-0.524-1.903,1.094c-0.038-0.02-0.079-0.042-0.106-0.057c-0.025-0.015-0.055-0.027-0.082-0.041
                                        c-0.021-0.011-0.037-0.021-0.041-0.021l0,0c-0.249-0.113-0.541-0.124-0.811-0.004c-0.499,0.225-0.719,0.809-0.496,1.306
                                        c0.136,0.302,0.402,0.501,0.705,0.563c0.078,0.04,0.163,0.087,0.274,0.149c2.456,1.36,12.886,7.786,18.931,21.248
                                        c1.802,4.008,3.218,8.645,3.93,13.973c0.002,0.135,0.027,0.271,0.086,0.4c0.225,0.498,0.809,0.721,1.307,0.498
                                        c0.494-0.223,0.715-0.799,0.502-1.292c-0.067-0.488-0.146-0.965-0.224-1.442l0.002-0.006c0,0-0.028-2.566,2.231-4.31
                                        c1.968-1.519-1.633-2.003-3.165-0.22c-0.768-3.049-1.759-5.845-2.907-8.395c-0.247-0.547-0.5-1.078-0.76-1.604
                                        c-0.144-1.049-0.328-4.164,2.002-6.963C521.199,194.67,514.576,195.439,513.354,199.291z"
                />
              </g>
              <g>
                <path
                  fill="#A2DB69"
                  d="M528.093,196.464c0.938-2.087,2.765-3.489,4.833-3.978c2.443-0.656,6.447-0.873,8.168-2.011
                                        c2.098-1.386,0.719-6.421,0.719-6.421s0.008-0.017,0.014-0.026c-0.293-1.186-0.211-2.473,0.327-3.673
                                        c0.573-1.273,1.562-2.229,2.731-2.784c0.004-0.012,0.008-0.026,0.012-0.039c0.281-0.108,0.538-0.192,0.785-0.269
                                        c0.065-0.018,0.129-0.04,0.192-0.058c2.425-0.672,3.456,0.21,7.44,0.311c3.38,0.09,4.828-3.646,5.334-5.487
                                        c0.049-0.458,0.166-0.914,0.363-1.354c0.855-1.901,2.883-2.901,4.844-2.537c0.057-0.012,0.115-0.027,0.162-0.032
                                        c10.523,2.368,13.861,0.032,18.318,0.717c4.461,0.686,5.427,1.122,6.25,3.429c0.822,2.307-3.929,3.737-6.056,6.23
                                        c-1.687,1.973-2.017,6.008-2.079,7.605c0.049,0.703-0.07,1.428-0.379,2.114c-0.691,1.538-2.147,2.488-3.719,2.604
                                        c-1.986,0.331-5.492-1.504-7.041-0.94c-1.621,0.589-2.832,1.904-0.744,5.362c2.59,3.722,3.502,6.611,3.502,6.611l-0.016,0.018
                                        c0.492,1.34,0.485,2.869-0.148,4.276c-0.578,1.291-1.584,2.254-2.775,2.806c-1.158,0.605-3.094,0.437-5.107,3.469
                                        c-1.797,2.709,0.68,6.684,1.807,8.244c1.754,2.018,2.291,4.943,1.122,7.542c-1.601,3.562-5.784,5.151-9.347,3.548
                                        c-0.104-0.046-0.199-0.102-0.299-0.15c-0.002,0.002-0.006,0.002-0.008,0.002c-0.024-0.015-0.049-0.029-0.074-0.045
                                        c-0.244-0.128-0.477-0.265-0.7-0.416c-8.704-5.037-11.985-1.245-11.985-1.245c-6.416-2.882-6.531-3.399-7.584-7.438
                                        c-1.811-5.926-6.271-15.191-8.107-18.902C527.391,201.563,527.01,198.871,528.093,196.464z"
                />
                <path
                  fill="#80C46A"
                  d="M532.926,192.486c2.443-0.656,6.447-0.873,8.168-2.011c2.098-1.386,0.719-6.421,0.719-6.421
                                        s0.008-0.017,0.014-0.026c-0.293-1.186-0.211-2.473,0.327-3.673c0.573-1.273,1.562-2.229,2.731-2.784
                                        c0.004-0.012,0.008-0.026,0.012-0.039c0.281-0.108,0.538-0.192,0.785-0.269c0.065-0.018,0.129-0.04,0.192-0.058
                                        c2.425-0.672,3.456,0.21,7.44,0.311c3.38,0.09,4.828-3.646,5.334-5.487c0.049-0.458,0.166-0.914,0.363-1.354
                                        c0.377-0.839,0.982-1.501,1.71-1.948c2.958,4.145,10.46,6.88,17.303,4.603c9.114-3.034,8.511-0.308,6.997-0.16
                                        c-1.514,0.146-6.775,4.498-5.493,9c1.28,4.504-8.675-1.626-10.54,0.225c-1.865,1.849,0.003,4.133-3.75,2.137
                                        c-3.753-1.998-6.022-1.777-1.837,4.548c4.183,6.325-3.938,2.778-7.6,1.96c-3.663-0.821-0.72,5.049,8.956,7.435
                                        c9.676,2.388-4.211,2.241-12.894,4.54c-8.681,2.298,1.536,3.48,6.229,4.765c4.695,1.283,2.357,3.952-0.635,3.021
                                        c-2.99-0.931-10.388,0.81-8.029,4.762c2.355,3.953,11.498,3.617,12.557,6.781c1.058,3.163-2.203,2.832-12.622,1.87
                                        c-7.37-0.682-7.673,2.587-7.222,4.56c-4.068-1.991-4.285-2.868-5.188-6.325c-1.811-5.926-6.271-15.191-8.107-18.902
                                        c-1.457-1.984-1.838-4.676-0.755-7.083C529.031,194.377,530.857,192.975,532.926,192.486z"
                />
                <path
                  fill="#A2DB69"
                  d="M533.227,199.186c-0.981,2.186,2.018,2.502,3.826,4.821c3.477,4.456,4.944,4.64,5.926,2.456
                                        s-0.434-7.842-3.115-9.048C537.179,196.209,534.209,197,533.227,199.186z"
                />
                <path
                  fill="#A2DB69"
                  d="M545.58,183.225c0.105,1.936,2.381,1.05,4.543,2.046c4.154,1.915,5.277,1.501,5.175-0.435
                                        c-0.106-1.938-3.226-5.48-5.603-5.35C547.316,179.614,545.475,181.289,545.58,183.225z"
                />
                <path
                  fill="#4AA957"
                  d="M551.965,194.254c2.297-3.448,4.758-6.348,7.129-8.739c0.43-1.007,1.014-3.021,0.152-5.353
                                        c-1.09-2.943,3.148-0.991,3.504,1.966c2.449-2.082,4.615-3.567,6.137-4.516c0.133-0.667,0.156-1.526-0.192-2.465
                                        c-0.694-1.882,2.032-0.613,2.229,1.281c0.047-0.023,0.092-0.049,0.125-0.067c0.031-0.02,0.064-0.031,0.096-0.048
                                        c0.025-0.013,0.044-0.023,0.048-0.023h0.001c0.291-0.135,0.634-0.146,0.947-0.006c0.586,0.264,0.846,0.947,0.582,1.531
                                        c-0.16,0.353-0.473,0.586-0.826,0.66c-0.089,0.045-0.189,0.102-0.32,0.172c-2.875,1.593-15.093,9.119-22.171,24.887
                                        c-2.111,4.695-3.771,10.125-4.604,16.366c-0.001,0.157-0.03,0.316-0.099,0.469c-0.264,0.585-0.947,0.844-1.53,0.582
                                        c-0.578-0.259-0.838-0.933-0.588-1.511c0.076-0.573,0.17-1.133,0.26-1.69l-0.003-0.006c0,0,0.036-3.006-2.612-5.05
                                        c-2.305-1.777,1.913-2.344,3.707-0.256c0.898-3.569,2.061-6.846,3.404-9.833c0.289-0.642,0.588-1.263,0.891-1.878
                                        c0.17-1.229,0.385-4.877-2.345-8.155C542.779,188.842,550.536,189.741,551.965,194.254z"
                />
              </g>
              <g>
                <path
                  fill="#C5E201"
                  d="M558.777,203.758c0-2.592-1.236-4.891-3.146-6.355c-2.221-1.814-6.258-3.898-7.51-5.875
                                        c-1.521-2.403,2.243-6.971,2.243-6.971s-0.001-0.021-0.003-0.032c0.854-1.089,1.367-2.457,1.367-3.946
                                        c0-1.585-0.578-3.029-1.528-4.148c0.003-0.013,0.003-0.03,0.003-0.044c-0.238-0.244-0.465-0.451-0.686-0.644
                                        c-0.057-0.05-0.115-0.101-0.172-0.149c-2.189-1.822-3.668-1.388-7.836-3.133c-3.533-1.481-3.292-6.015-2.96-8.154
                                        c0.161-0.494,0.253-1.019,0.253-1.568c0-2.362-1.633-4.336-3.83-4.873c-0.053-0.039-0.104-0.082-0.15-0.105
                                        c-11.977-2.444-14.34-6.41-19.268-7.771c-4.926-1.361-6.129-1.361-8.051,0.641c-1.924,2.002,2.322,5.687,3.365,9.253
                                        c0.824,2.824-0.711,7.145-1.385,8.825c-0.377,0.705-0.592,1.508-0.592,2.363c0,1.908,1.064,3.568,2.631,4.417
                                        c1.9,1.265,6.377,0.999,7.717,2.3c1.402,1.361,2.043,3.284-1.723,5.888c-4.406,2.645-6.689,5.207-6.689,5.207l0.008,0.025
                                        c-1.135,1.156-1.837,2.738-1.837,4.486c0,1.604,0.594,3.066,1.566,4.191c0.917,1.163,2.993,1.889,3.667,5.957
                                        c0.6,3.635-3.807,6.595-5.697,7.682c-2.75,1.27-4.664,4.045-4.664,7.273c0,4.426,3.588,8.014,8.012,8.014
                                        c0.129,0,0.254-0.015,0.381-0.021c0.003,0.002,0.006,0.005,0.007,0.007c0.034-0.005,0.063-0.007,0.099-0.013
                                        c0.311-0.017,0.613-0.053,0.916-0.104c11.336-1.162,12.965,4.282,12.965,4.282c7.973,0,8.332-0.48,11.296-4.166
                                        c4.624-5.283,13.539-12.786,17.159-15.769C557.135,209.354,558.777,206.749,558.777,203.758z"
                />
                <path
                  fill="#A5D600"
                  d="M555.631,197.402c-2.221-1.814-6.258-3.898-7.51-5.875c-1.521-2.403,2.243-6.971,2.243-6.971
                                        s-0.001-0.021-0.003-0.032c0.854-1.089,1.367-2.457,1.367-3.946c0-1.585-0.578-3.029-1.528-4.148
                                        c0.003-0.013,0.003-0.03,0.003-0.044c-0.238-0.244-0.465-0.451-0.686-0.644c-0.057-0.05-0.115-0.101-0.172-0.149
                                        c-2.189-1.822-3.668-1.388-7.836-3.133c-3.533-1.481-3.292-6.015-2.96-8.154c0.161-0.494,0.253-1.019,0.253-1.568
                                        c0-1.042-0.318-2.007-0.861-2.808c-4.984,2.91-14.008,2.253-20.023-3.28c-8.012-7.371-8.652-4.272-7.156-3.417
                                        c1.496,0.854,4.913,7.795,1.496,11.857c-3.418,4.057,9.721,2.349,10.788,5.126c1.067,2.777-1.923,4.273,2.886,3.952
                                        c4.805-0.321,7.047,0.961-0.215,5.555c-7.264,4.593,2.777,4.7,6.943,5.554c4.166,0.856-1.604,5.555-12.713,3.525
                                        c-11.107-2.028,3.313,4.271,11.217,10.683c7.905,6.409-3.203,2.884-8.652,2.029c-5.447-0.854-4.272,2.99-0.748,3.418
                                        c3.525,0.427,10.361,5.661,6.089,8.652c-4.274,2.99-13.565-1.603-16.129,1.175s0.962,3.953,12.177,7.799
                                        c7.932,2.719,6.729,6.236,5.344,8.066c5.131-0.168,5.765-0.973,8.302-4.128c4.624-5.283,13.539-12.786,17.159-15.769
                                        c2.43-1.375,4.072-3.979,4.072-6.971C558.777,201.166,557.541,198.867,555.631,197.402z"
                />
                <path
                  fill="#C5E201"
                  d="M552.207,204.186c0,2.713-3.248,1.646-6.195,3.205c-5.662,2.99-7.264,2.5-7.264-0.215
                                        c0-2.713,4.09-7.904,7.424-7.904C549.505,199.271,552.207,201.472,552.207,204.186z"
                />
                <path
                  fill="#C5E201"
                  d="M546.854,181.949c-1.008,1.952-2.948-0.021-5.647,0.004c-5.181,0.048-6.151-0.899-5.144-2.853
                                        c1.007-1.952,5.878-4.165,8.274-2.927C546.736,177.411,547.862,179.998,546.854,181.949z"
                />
                <path
                  fill="#87BE00"
                  d="M535.131,190.381c-0.773-4.629-1.969-8.769-3.307-12.343c0.021-1.241,0.355-3.592,2.33-5.602
                                        c2.491-2.537-2.795-2.488-4.537,0.401c-1.564-3.288-3.111-5.828-4.244-7.518c0.174-0.749,0.549-1.648,1.346-2.458
                                        c1.592-1.623-1.816-1.581-2.902,0.289c-0.033-0.047-0.07-0.094-0.098-0.126c-0.022-0.034-0.051-0.063-0.076-0.094
                                        c-0.02-0.025-0.035-0.044-0.037-0.048l-0.002,0.002c-0.24-0.273-0.586-0.449-0.978-0.449c-0.726,0-1.313,0.588-1.313,1.313
                                        c0,0.438,0.218,0.826,0.549,1.063c0.069,0.091,0.149,0.194,0.249,0.33c2.233,2.983,11.364,16.436,11.355,36.022
                                        c0,5.834-0.811,12.218-2.848,19.055c-0.07,0.163-0.113,0.341-0.113,0.531c0,0.726,0.588,1.312,1.313,1.312
                                        c0.717,0,1.299-0.576,1.309-1.29c0.188-0.628,0.35-1.247,0.516-1.866l0.004-0.005c0,0,1.363-3.124,5.049-4.006
                                        c3.207-0.767-0.889-3.312-3.712-1.987c0.729-4.106,1.048-8.033,1.048-11.743c0-0.795-0.019-1.578-0.047-2.354
                                        c0.395-1.35,1.867-5.221,6.212-7.34C547.139,189.057,538.703,186.383,535.131,190.381z"
                />
              </g>
              <path
                fill="#7B6769"
                d="M548.402,228.099c-0.658,0-1.277,0.165-1.822,0.45c-0.748-1.365-2.198-2.292-3.866-2.292
                                    c-0.798,0-1.54,0.214-2.187,0.583c-0.482-0.915-1.433-1.543-2.539-1.543c-1.031,0-1.93,0.544-2.441,1.357
                                    c-0.429-0.2-0.9-0.317-1.404-0.317c-0.66,0-1.27,0.196-1.789,0.524c-0.705-1.493-2.22-2.527-3.98-2.527
                                    c-1.708,0-3.187,0.973-3.917,2.394c-0.347-0.147-0.729-0.23-1.13-0.23c-1.323,0-2.428,0.897-2.768,2.114
                                    c-0.43-0.27-0.934-0.432-1.479-0.432c-0.535,0-1.03,0.158-1.458,0.42c-0.806-0.896-1.97-1.461-3.27-1.461
                                    c-2.433,0-4.404,1.973-4.404,4.405c0,2.325,1.801,4.226,4.086,4.39v0.018h34.286v-0.005c0.029,0,0.055,0.005,0.082,0.005
                                    c2.166,0,3.926-1.758,3.926-3.927C552.328,229.856,550.568,228.099,548.402,228.099z"
              />
            </g>
            <g>
              <g>
                <path
                  fill="#D5CDBD"
                  d="M568.031,252.895c0,10.398-9.518,18.907-21.15,18.907h-32.367c-11.632,0-21.148-8.509-21.148-18.907
                                        s9.517-18.906,21.148-18.906h32.367C558.514,233.988,568.031,242.496,568.031,252.895z"
                />
              </g>
            </g>
            <path
              fill="#EAE6DE"
              d="M545.827,233.988h-31.313c-11.632,0-21.148,8.508-21.148,18.906c0,4.68,1.932,8.974,5.113,12.285h31.311
                                c11.633,0,21.15-8.509,21.15-18.908C550.939,241.593,549.01,237.299,545.827,233.988z"
            />
            <g>
              <g>
                <path
                  fill="#F4F2EE"
                  d="M526.799,255.458c0,3.912-3.581,7.114-7.957,7.114h-12.178c-4.377,0-7.957-3.202-7.957-7.114
                                        s3.58-7.114,7.957-7.114h12.178C523.218,248.344,526.799,251.546,526.799,255.458z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  fill="#F4F2EE"
                  d="M544.959,239.648c0,1.83-1.677,3.326-3.723,3.326h-5.695c-2.047,0-3.721-1.496-3.721-3.326
                                        s1.674-3.327,3.721-3.327h5.695C543.282,236.321,544.959,237.818,544.959,239.648z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  fill="#F4F2EE"
                  d="M521.873,238.154c0,1.36-1.245,2.473-2.766,2.473h-4.232c-1.521,0-2.766-1.112-2.766-2.473
                                        s1.244-2.472,2.766-2.472h4.232C520.628,235.683,521.873,236.794,521.873,238.154z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  fill="#E0DBD0"
                  d="M553.684,267.209c0,1.829-1.676,3.327-3.722,3.327h-5.697c-2.046,0-3.721-1.498-3.721-3.327
                                        s1.675-3.328,3.721-3.328h5.697C552.008,263.881,553.684,265.38,553.684,267.209z"
                />
              </g>
            </g>
          </g>
          <path
            opacity="0.2"
            fill="#BCB2A2"
            d="M560.595,266.713h-59.706c-0.232,0-0.458,0.027-0.68,0.074
                            c3.773,3.107,8.803,5.015,14.305,5.015h32.367c5.511,0,10.545-1.912,14.32-5.028C561.004,266.736,560.801,266.713,560.595,266.713
                            z"
          />
          <path
            fill="#EAE6DE"
            d="M564.4,271.721c0,2.102-1.703,3.807-3.806,3.807h-59.706c-2.103,0-3.806-1.705-3.806-3.807l0,0
                            c0-2.103,1.703-3.807,3.806-3.807h59.706C562.697,267.914,564.4,269.618,564.4,271.721L564.4,271.721z"
          />
          <path
            opacity="0.2"
            fill="#BCB2A2"
            d="M500.889,273.424c-1.016,0-1.932,0.404-2.615,1.051c0.684,0.649,1.6,1.053,2.615,1.053
                            h59.706c1.014,0,1.933-0.403,2.614-1.053c-0.682-0.646-1.601-1.051-2.614-1.051H500.889z"
          />
        </g>
        <path
          fill="#D8ECF3"
          d="M441.902,136.29c-0.006-2.665-2.201-4.862-4.869-4.869l-196.292,0.002
                        c-2.666,0.004-4.863,2.202-4.869,4.867v41.555h0.001v87.415H441.9v-87.415h0.002V136.29z"
        />
        <path
          fill="#152054"
          d="M443.873,137.317c-0.008-4.42-3.596-8.009-8.018-8.015l-193.941,0.002
                        c-4.418,0.004-8.007,3.592-8.012,8.013v41.057h0v89.575h209.971V137.317z M440.667,264.743H237.108v-86.37h-0.002v-41.057
                        c0.005-2.633,2.176-4.805,4.809-4.809l193.941-0.001c2.635,0.005,4.807,2.177,4.813,4.81v41.057h-0.001V264.743z"
        />
        <g>
          <g>
            <path
              fill="#D8ECF3"
              d="M461.283,267.916c0-0.892-0.732-1.624-1.623-1.624H218.743c-0.894,0-1.624,0.732-1.624,1.624v7.037
                                c0,0.895,0.73,1.626,1.624,1.626H459.66c0.891,0,1.623-0.731,1.623-1.626V267.916z"
            />
          </g>
        </g>
        <path
          fill="#B9D0D6"
          d="M217.119,271.722v3.231c0,0.895,0.73,1.626,1.624,1.626H459.66c0.891,0,1.623-0.731,1.623-1.626v-3.231
                        H217.119z"
        />
        <g>
          <path
            fill="#F4FAFE"
            d="M435.027,257.072c0,0.894-0.73,1.625-1.625,1.625H245c-0.893,0-1.624-0.731-1.624-1.625V140.944
                            c0-0.894,0.731-1.623,1.624-1.623h188.403c0.895,0,1.625,0.729,1.625,1.623V257.072z"
          />
        </g>
        <g>
          <g>
            <path
              fill="#152054"
              d="M218.743,278.18c-1.787-0.004-3.221-1.441-3.228-3.227l0,0v-7.037c0.007-1.785,1.44-3.221,3.228-3.228
                                l0,0l240.917,0.001c1.785,0.006,3.219,1.44,3.226,3.227l0,0v7.037c-0.007,1.785-1.44,3.223-3.226,3.229l0,0L218.743,278.18
                                L218.743,278.18z M218.72,267.916v7.037l0.023,0.021l240.917,0.001c0-0.001,0.02-0.022,0.02-0.022l0,0v-7.037l-0.02-0.021
                                l-240.917-0.002C218.743,267.895,218.72,267.916,218.72,267.916L218.72,267.916z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#F3885F"
              d="M105.904,292.458v33.472c-1.897-1.521-4.136-2.417-6.542-2.417c-6.805,0-12.322,7.042-12.322,15.728
                                c0,0.171,0.016,0.335,0.019,0.502H86.57v-47.284H105.904z"
            />
            <rect
              x="85.521"
              y="293.06"
              opacity="0.3"
              fill="#152054"
              width="21.137"
              height="10.718"
            />
            <g>
              <g>
                <path
                  fill="#152054"
                  d="M105.739,335.434c0.881,0,1.603-0.719,1.603-1.602V293.76c0-0.881-0.721-1.603-1.603-1.603h-0.001
                                        c-0.882,0-1.603,0.722-1.603,1.603v40.072C104.135,334.715,104.855,335.434,105.739,335.434L105.739,335.434z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  fill="#152054"
                  d="M86.571,344.049c0.882,0,1.604-0.723,1.604-1.602V293.76c0-0.881-0.722-1.604-1.604-1.604l0,0
                                        c-0.881,0-1.603,0.723-1.603,1.604v48.688C84.968,343.326,85.69,344.049,86.571,344.049L86.571,344.049z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  fill="#152054"
                  d="M86.571,353.399c0.881,0,1.602-0.722,1.602-1.603v-1.137c0-0.882-0.721-1.602-1.602-1.602
                                        s-1.603,0.72-1.603,1.602v1.137C84.968,352.678,85.69,353.399,86.571,353.399z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  fill="#152054"
                  d="M105.739,353.399c0.881,0,1.603-0.722,1.603-1.603v-9.148c0-0.882-0.721-1.604-1.603-1.604l0,0
                                        c-0.881,0-1.604,0.722-1.604,1.604v9.148C104.135,352.678,104.857,353.399,105.739,353.399L105.739,353.399z"
                />
              </g>
            </g>
          </g>
          <g>
            <path
              fill="#F3885F"
              d="M504.212,292.458v33.472c1.896-1.521,4.136-2.417,6.542-2.417c6.807,0,12.321,7.042,12.321,15.728
                                c0,0.171-0.015,0.335-0.019,0.502h0.488v-47.284H504.212z"
            />
            <rect
              x="503.861"
              y="293.06"
              opacity="0.3"
              fill="#152054"
              width="21.139"
              height="10.718"
            />
            <g>
              <g>
                <path
                  fill="#152054"
                  d="M504.376,335.434c-0.881,0-1.603-0.719-1.603-1.602V293.76c0-0.881,0.722-1.603,1.603-1.603h0.001
                                        c0.883,0,1.604,0.722,1.604,1.603v40.072C505.98,334.715,505.26,335.434,504.376,335.434L504.376,335.434z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  fill="#152054"
                  d="M523.543,344.049c-0.883,0-1.604-0.723-1.604-1.602V293.76c0-0.881,0.721-1.604,1.604-1.604h0.002
                                        c0.881,0,1.604,0.723,1.604,1.604v48.688c0,0.879-0.723,1.602-1.604,1.602H523.543z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  fill="#152054"
                  d="M523.545,353.399c-0.883,0-1.604-0.722-1.604-1.603v-1.137c0-0.882,0.721-1.602,1.604-1.602
                                        c0.881,0,1.601,0.72,1.601,1.602v1.137C525.146,352.678,524.426,353.399,523.545,353.399z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  fill="#152054"
                  d="M504.376,353.399c-0.881,0-1.603-0.722-1.603-1.603v-9.148c0-0.882,0.722-1.604,1.603-1.604l0,0
                                        c0.884,0,1.604,0.722,1.604,1.604v9.148C505.98,352.678,505.26,353.399,504.376,353.399L504.376,353.399z"
                />
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <path
                  fill="#F8A382"
                  d="M577.004,286.082c0,3.526-2.883,6.41-6.408,6.41H39.521c-3.526,0-6.412-2.884-6.412-6.41v-2.673
                                        c0-3.526,2.886-6.411,6.412-6.411h531.074c3.525,0,6.408,2.885,6.408,6.411V286.082z"
                />
              </g>
            </g>
            <path
              fill="#F3885F"
              d="M33.109,285.947v0.135c0,3.526,2.886,6.41,6.412,6.41h531.074c3.525,0,6.408-2.884,6.408-6.41v-0.135
                                H33.109z"
            />
            <g>
              <g>
                <g>
                  <path
                    fill="#152054"
                    d="M39.521,294.096c-4.419-0.008-8.009-3.596-8.013-8.015l0,0v-2.672
                                            c0.004-4.419,3.594-8.009,8.013-8.016l0,0l531.074,0.002c4.417,0.005,8.006,3.595,8.014,8.014l0,0v2.672
                                            c-0.008,4.419-3.597,8.007-8.014,8.015l0,0H39.521L39.521,294.096z M34.712,283.409v2.672
                                            c0.006,2.634,2.174,4.803,4.809,4.808l0,0h531.074c2.633-0.005,4.803-2.174,4.807-4.808l0,0v-2.672
                                            c-0.004-2.634-2.174-4.806-4.807-4.81l0,0H39.521C36.886,278.604,34.718,280.775,34.712,283.409L34.712,283.409z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#D8ECF3"
              d="M66.014,247.594c-5.29,0-9.618,4.33-9.618,9.619v6.811c0,5.289,4.328,9.618,9.618,9.618h73.33v-26.048
                                H66.014z"
            />
            <path
              fill="#9ECCD8"
              d="M66.014,273.642h73.33v-5.344H57.416C58.998,271.455,62.259,273.642,66.014,273.642z"
            />
            <g>
              <g>
                <g>
                  <path
                    fill="#152054"
                    d="M72.22,262.354c-0.882,0-1.603-0.721-1.603-1.604l0,0c0-0.882,0.72-1.604,1.603-1.604h31.193
                                            c0.882,0,1.602,0.722,1.602,1.604l0,0c0,0.883-0.72,1.604-1.602,1.604H72.22z"
                  />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <path
                    fill="#152054"
                    d="M110.888,262.354c-0.882,0-1.603-0.721-1.603-1.604l0,0c0-0.882,0.721-1.604,1.603-1.604h2.674
                                            c0.882,0,1.604,0.722,1.604,1.604l0,0c0,0.883-0.721,1.604-1.604,1.604H110.888z"
                  />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <path
                    fill="#152054"
                    d="M129.925,262.354c-0.882,0-1.602-0.721-1.602-1.604l0,0c0-0.882,0.72-1.604,1.602-1.604h8.151
                                            c0.881,0,1.603,0.722,1.603,1.604l0,0c0,0.883-0.722,1.604-1.603,1.604H129.925z"
                  />
                </g>
              </g>
            </g>
            <path
              fill="#152054"
              d="M145.291,272.304h-4.679v-23.642h4.679c0.882,0,1.603-0.721,1.603-1.603c0-0.88-0.721-1.603-1.603-1.603
                                h-34.063v-0.001H67.353c-7.064,0.007-12.818,5.759-12.824,12.824v4.407c0.006,7.063,5.76,12.814,12.824,12.823h77.938
                                c0.882,0,1.603-0.722,1.603-1.603v-0.002C146.894,273.025,146.172,272.304,145.291,272.304z M67.353,272.304
                                c-5.278-0.01-9.608-4.339-9.616-9.616l-0.002-4.407c0.01-5.279,4.34-9.608,9.618-9.618h70.052v23.642H67.353z"
            />
            <g>
              <polygon
                fill="#F55D6C"
                points="87.317,278.699 79.252,274.641 71.289,278.699 71.289,260.75 87.317,260.75 				"
              />
              <g>
                <g>
                  <path
                    fill="#152054"
                    d="M69.686,259.146H88.92v19.553v2.6l-9.663-4.863l-9.571,4.877V259.146L69.686,259.146z M87.318,278.699
                                            l0.718-1.435L87.318,278.699L87.318,278.699z M85.714,276.097v-13.743H72.892v13.729l6.356-3.236L85.714,276.097
                                            L85.714,276.097z"
                  />
                </g>
              </g>
            </g>
          </g>
          <g>
            <path
              fill="#D8ECF3"
              d="M123.566,223.86c4.107,0,7.467,3.36,7.467,7.468v5.289c0,4.108-3.36,7.469-7.467,7.469H66.628V223.86
                                H123.566z"
            />
            <path
              fill="#9ECCD8"
              d="M123.566,244.086H66.628v-4.149h63.615C129.015,242.389,126.483,244.086,123.566,244.086z"
            />
            <g>
              <g>
                <g>
                  <path
                    fill="#152054"
                    d="M118.748,235.319c0.684,0,1.245-0.56,1.245-1.243v-0.002c0-0.685-0.561-1.244-1.245-1.244H94.527
                                            c-0.685,0-1.245,0.56-1.245,1.244v0.002c0,0.684,0.56,1.243,1.245,1.243H118.748z"
                  />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <path
                    fill="#152054"
                    d="M88.722,235.319c0.685,0,1.245-0.56,1.245-1.243v-0.002c0-0.685-0.56-1.244-1.245-1.244h-2.078
                                            c-0.684,0-1.244,0.56-1.244,1.244v0.002c0,0.684,0.56,1.243,1.244,1.243H88.722z"
                  />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <path
                    fill="#152054"
                    d="M73.941,235.319c0.685,0,1.243-0.56,1.243-1.243v-0.002c0-0.685-0.559-1.244-1.243-1.244h-6.33
                                            c-0.684,0-1.244,0.56-1.244,1.244v0.002c0,0.684,0.56,1.243,1.244,1.243H73.941z"
                  />
                </g>
              </g>
            </g>
            <path
              fill="#152054"
              d="M60.765,244.291v0.002c0,0.684,0.56,1.243,1.244,1.243h60.519c5.486-0.006,9.953-4.472,9.957-9.957
                                v-3.421c-0.004-5.487-4.471-9.955-9.957-9.958H88.459v0.001H62.008c-0.684,0-1.244,0.56-1.244,1.243
                                c0,0.685,0.56,1.245,1.244,1.245h3.634v18.357h-3.634C61.325,243.047,60.765,243.606,60.765,244.291z M68.13,243.047v-18.357
                                h54.397c4.1,0.007,7.46,3.367,7.468,7.469l-0.001,3.421c-0.007,4.099-3.367,7.459-7.467,7.468H68.13z"
            />
            <g>
              <polygon
                fill="#F55D6C"
                points="107.025,248.012 113.286,244.861 119.47,248.012 119.47,234.076 107.025,234.076 				"
              />
              <g>
                <g>
                  <path
                    fill="#152054"
                    d="M120.715,232.83v17.211l-7.432-3.786l-7.503,3.775v-2.019V232.83H120.715L120.715,232.83z
                                            M107.025,248.012l-0.56-1.113L107.025,248.012L107.025,248.012z M108.27,245.991l5.021-2.525l4.936,2.515v-10.661h-9.957
                                            V245.991L108.27,245.991z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <g opacity="0.3">
          <g>
            <g>
              <g>
                <path
                  fill="#D8ECF3"
                  d="M419.586,92.875c0,4.719-3.861,8.581-8.582,8.581h-51.489c-4.719,0-8.58-3.862-8.58-8.581V79.114
                                        c0-4.719,3.861-8.582,8.58-8.582h51.489c4.721,0,8.582,3.863,8.582,8.582V92.875z"
                />
              </g>
            </g>
            <g>
              <path
                fill="#B9D0D6"
                d="M419.586,94.329c0,3.921-3.594,7.127-7.985,7.127h-47.913c-4.393,0-7.984-3.207-7.984-7.127V82.903
                                    c0-3.92,3.592-7.126,7.984-7.126h47.913c4.392,0,7.985,3.207,7.985,7.126V94.329z"
              />
            </g>
            <g>
              <path
                fill="#152054"
                d="M349.504,92.873V79.114c0.005-5.517,4.496-10.006,10.011-10.012l0,0l51.489-0.001
                                    c5.516,0.006,10.007,4.496,10.012,10.013l0,0v13.758c-0.005,5.518-4.496,10.007-10.012,10.013l0,0l-51.489,0.002
                                    C354,102.879,349.509,98.391,349.504,92.873L349.504,92.873z M359.515,71.964c-3.923,0.006-7.145,3.227-7.149,7.15l0,0v13.758
                                    c0.005,3.925,3.227,7.146,7.149,7.154l0,0l51.489-0.002c3.923-0.006,7.145-3.226,7.152-7.151l0,0V79.114
                                    c-0.008-3.924-3.229-7.145-7.152-7.152l0,0L359.515,71.964L359.515,71.964z"
              />
            </g>
          </g>
          <g>
            <g>
              <g>
                <path
                  fill="#D8ECF3"
                  d="M451.14,61.946c0,4.719-3.861,8.581-8.581,8.581H391.07c-4.719,0-8.581-3.862-8.581-8.581V48.186
                                        c0-4.719,3.862-8.581,8.581-8.581h51.488c4.72,0,8.581,3.862,8.581,8.581V61.946z"
                />
              </g>
            </g>
            <g>
              <path
                fill="#B9D0D6"
                d="M451.14,63.402c0,3.918-3.593,7.125-7.983,7.125H395.24c-4.389,0-7.982-3.207-7.982-7.125V51.974
                                    c0-3.919,3.594-7.125,7.982-7.125h47.916c4.391,0,7.983,3.205,7.983,7.125V63.402z"
              />
            </g>
            <g>
              <path
                fill="#152054"
                d="M381.059,61.945l-0.001-13.759c0.007-5.516,4.495-10.007,10.013-10.012l0,0l51.488-0.002
                                    c5.516,0.007,10.006,4.498,10.012,10.014l0,0v13.759c-0.006,5.517-4.496,10.006-10.012,10.012l0,0H391.07
                                    C385.553,71.951,381.064,67.462,381.059,61.945L381.059,61.945z M391.07,41.035c-3.924,0.006-7.145,3.227-7.15,7.151l0,0v13.759
                                    c0.006,3.924,3.227,7.146,7.15,7.153l0,0l51.488-0.002c3.922-0.005,7.144-3.227,7.151-7.151l0,0V48.186
                                    c-0.008-3.924-3.229-7.145-7.151-7.152l0,0L391.07,41.035L391.07,41.035z"
              />
            </g>
          </g>
        </g>
        <g opacity="0.3">
          <g>
            <g>
              <g>
                <path
                  fill="#D8ECF3"
                  d="M311.226,365.332c0,4.72-3.862,8.581-8.581,8.581h-51.489c-4.719,0-8.581-3.861-8.581-8.581v-13.761
                                        c0-4.72,3.862-8.581,8.581-8.581h51.489c4.719,0,8.581,3.861,8.581,8.581V365.332z"
                />
              </g>
            </g>
            <g>
              <path
                fill="#B9D0D6"
                d="M311.226,366.787c0,3.92-3.593,7.126-7.985,7.126h-47.913c-4.392,0-7.984-3.206-7.984-7.126v-11.426
                                    c0-3.919,3.593-7.127,7.984-7.127h47.913c4.392,0,7.985,3.208,7.985,7.127V366.787z"
              />
            </g>
            <g>
              <path
                fill="#152054"
                d="M241.145,365.331l-0.001-13.76c0.006-5.517,4.496-10.005,10.012-10.011l0,0h51.489
                                    c5.515,0.006,10.005,4.494,10.011,10.011l0,0v13.76c-0.006,5.516-4.497,10.008-10.011,10.011l0,0l-51.489,0.003
                                    C245.64,375.339,241.15,370.847,241.145,365.331L241.145,365.331z M251.156,344.421c-3.924,0.006-7.145,3.229-7.151,7.15l0,0
                                    v13.76c0.006,3.924,3.227,7.146,7.151,7.153l0,0l51.489-0.004c3.922-0.003,7.143-3.226,7.15-7.149l0,0v-13.76
                                    c-0.007-3.922-3.228-7.145-7.15-7.153l0,0L251.156,344.421L251.156,344.421z"
              />
            </g>
          </g>
          <g>
            <g>
              <g>
                <path
                  fill="#D8ECF3"
                  d="M280.564,334.404c0,4.719-3.859,8.582-8.58,8.582h-51.489c-4.719,0-8.581-3.863-8.581-8.582v-13.762
                                        c0-4.719,3.861-8.58,8.581-8.58h51.489c4.721,0,8.58,3.861,8.58,8.58V334.404z"
                />
              </g>
            </g>
            <g>
              <path
                fill="#B9D0D6"
                d="M280.564,335.858c0,3.919-3.592,7.128-7.984,7.128h-47.913c-4.393,0-7.985-3.209-7.985-7.128v-11.427
                                    c0-3.919,3.592-7.126,7.985-7.126h47.913c4.392,0,7.984,3.207,7.984,7.126V335.858z"
              />
            </g>
            <g>
              <path
                fill="#152054"
                d="M210.486,334.4l-0.002-13.758c0.005-5.517,4.495-10.006,10.012-10.012l0,0h51.489
                                    c5.515,0.006,10.006,4.495,10.011,10.012l0,0V334.4c-0.005,5.52-4.497,10.01-10.011,10.012l0,0l-51.489,0.002
                                    C214.979,344.41,210.489,339.92,210.486,334.4L210.486,334.4z M220.496,313.493c-3.923,0.006-7.145,3.227-7.151,7.149l0,0V334.4
                                    c0.007,3.926,3.229,7.148,7.151,7.154l0,0l51.489-0.002c3.922-0.004,7.143-3.227,7.151-7.152l0,0v-13.758
                                    c-0.008-3.923-3.229-7.144-7.151-7.151l0,0L220.496,313.493L220.496,313.493z"
              />
            </g>
          </g>
          <g>
            <g>
              <g>
                <path
                  fill="#D8ECF3"
                  d="M349.455,334.404c0,4.719-3.863,8.582-8.582,8.582h-51.488c-4.72,0-8.581-3.863-8.581-8.582v-13.762
                                        c0-4.719,3.861-8.58,8.581-8.58h51.488c4.719,0,8.582,3.861,8.582,8.58V334.404z"
                />
              </g>
            </g>
            <g>
              <path
                fill="#B9D0D6"
                d="M349.455,335.858c0,3.919-3.595,7.128-7.986,7.128h-47.912c-4.392,0-7.985-3.209-7.985-7.128v-11.427
                                    c0-3.919,3.594-7.126,7.985-7.126h47.912c4.392,0,7.986,3.207,7.986,7.126V335.858z"
              />
            </g>
            <g>
              <path
                fill="#152054"
                d="M279.374,334.4l-0.001-13.758c0.006-5.517,4.496-10.006,10.013-10.012l0,0h51.488
                                    c5.516,0.006,10.006,4.495,10.012,10.012l0,0V334.4c-0.006,5.52-4.496,10.01-10.012,10.012l0,0l-51.488,0.002
                                    C283.868,344.41,279.378,339.92,279.374,334.4L279.374,334.4z M289.385,313.493c-3.925,0.006-7.146,3.227-7.152,7.149l0,0V334.4
                                    c0.006,3.926,3.227,7.148,7.152,7.154l0,0l51.488-0.002c3.922-0.004,7.143-3.227,7.152-7.152l0,0v-13.758
                                    c-0.01-3.923-3.23-7.144-7.152-7.151l0,0L289.385,313.493L289.385,313.493z"
              />
            </g>
          </g>
        </g>
        <path
          opacity="0.2"
          fill="#FFFFFF"
          d="M278.115,139.321H245c-0.893,0-1.624,0.729-1.624,1.623v116.128
                        c0,0.894,0.731,1.625,1.624,1.625h153.051L278.115,139.321z"
        />
        <g>
          <g>
            <path
              fill="#152054"
              d="M244.999,260.299c-1.785-0.008-3.22-1.439-3.228-3.227l0,0V140.944c0.007-1.787,1.443-3.221,3.228-3.227
                                l0,0h188.403c1.783,0.005,3.221,1.439,3.227,3.227l0,0v116.128c-0.008,1.787-1.443,3.219-3.227,3.227l0,0H244.999
                                L244.999,260.299z M244.977,140.944v116.128l0.022,0.021h188.403l0.021-0.021V140.944c0-0.001-0.019-0.022-0.021-0.022l0,0
                                H244.999C244.999,140.922,244.977,140.943,244.977,140.944L244.977,140.944z"
            />
          </g>
        </g>
      </svg>
      <div class="about__content">
        <div
          class="about__title specialTitle"
          v-if="title.length || description.length"
        >
          <h3 class="about__subTitle specialTitle__sub">OUR COMPANY</h3>
          <h1 class="about__mainTitle specialTitle__main" v-if="title.length">
            {{ title.slice(0, 30) }}{{ title.length > 30 ? "..." : "" }}
          </h1>
        </div>
        <p class="about__desc" v-if="description.length">
          {{ description.slice(0, 1000)
          }}{{ description.length > 1000 ? "..." : "" }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { string } from "yup";

export default defineComponent({
  name: "AboutUs",
  props: {
    title: {
      type: String,
      default: "Some Fine Words About Us",
    },
    description: {
      type: String,
      default:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia dolor, ad quas ducimus iusto voluptas.",
    },
    imageUrl: {
      type: String,
    },
  },
});
</script>

<style></style>
