<template>
  <nav
    id="navbarExample"
    class="navbar navbar-expand-lg fixed-top navbar-light"
    aria-label="Main navigation"
  >
    <div class="container">
      <img
        style="width: 65px"
        v-if="websiteConfig?.self_preferences?.logo"
        :src="websiteConfig?.self_preferences?.logo"
      />
      <a class="navbar-brand logo-text" href="/">{{
        websiteConfig?.self_preferences?.display_name
      }}</a>
      <button
        class="navbar-toggler p-0 border-0"
        type="button"
        id="navbarSideCollapse"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="navbar-collapse offcanvas-collapse"
        id="navbarsExampleDefault"
      >
        <ul class="navbar-nav ms-auto navbar-nav-scroll">
          <li class="nav-item">
            <a
              class="nav-link"
              aria-current="page"
              @click="handleNavItemClick('home')"
              href="/#header"
              >Home</a
            >
          </li>
          <li class="nav-item" v-if="showServices">
            <a
              class="nav-link"
              @click="handleNavItemClick('Services')"
              href="/#services"
              >Services</a
            >
          </li>
          <li class="nav-item" v-if="showPackages">
            <a
              class="nav-link"
              @click="handleNavItemClick('Packages')"
              href="/#packages"
              >Packages</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              @click="handleNavItemClick('About us')"
              href="/#aboutus"
              >About us</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              @click="handleNavItemClick('Contact us')"
              href="/#contactus"
              >Contact us</a
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import analyticsService from "@/analytics.service";
import { WebsiteConfig } from "@/ag-flight-components/types/WebsiteConfig";
import { WEBSITE_ANALYTICS_EVENTS } from "@/constants/analyticsEvents";

export default defineComponent({
  name: "NavbarComponent",
  data() {
    return {
      title: "",
      logo: "",
    };
  },
  computed: {
    websiteConfig(): WebsiteConfig {
      return this.$store.getters.websiteConfig;
    },
    showServices(): boolean {
      return (
        this.websiteConfig?.services_detail?.is_enabled &&
        Boolean(this.websiteConfig?.services_detail?.service_list?.length)
      );
    },
    showPackages(): boolean {
      return (
        this.websiteConfig?.packages_detail?.is_enabled &&
        Boolean(this.websiteConfig?.packages_detail?.package_list?.length) &&
        Boolean(localStorage.getItem("isActivePackage") === "true")
      );
    },
  },
  methods: {
    handleNavItemClick(item: string) {
      const hostname = window.location.hostname;
      analyticsService.logActionEvent(
        WEBSITE_ANALYTICS_EVENTS.WEBSITE_NAVBAR_ITEM_CLICKED,
        { page: item, hostname }
      );
    },
  },
});
</script>
