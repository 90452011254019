import { ActionContext, ActionTree } from "vuex";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import dowload from "downloadjs";
import { StatusCodes } from "http-status-codes";

import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";

import { IFlightBooking } from "@/ag-flight-components/interfaces/IFlightBooking.interface";
import FlightBookingService from "@/ag-flight-components/services/booking.service";
import {
  GetBookingPayloadType,
  GetDownloadBookingPayloadType,
  ICancelBookingPayloadType,
  IConfirmBooking,
  IVoidBookingPayloadType,
} from "@/ag-flight-components/types/FlightBookingForm";
import { BookingFormTravelerData } from "@/ag-flight-components/constants/bookingForm";
import { TravelerTypes } from "@/ag-flight-components/constants/TravelerTypes";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";

const actions: ActionTree<IFlightBooking, IFlightBooking> = {
  // **** Get Flight Booking ****
  async getFlightBooking(
    context: ActionContext<IFlightBooking, IFlightBooking>,
    payload: GetBookingPayloadType
  ) {
    const methodName = "actions.getFlightBooking";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setLoading", true);
      const flightBooking = new FlightBookingService();
      const response: IAGResponse<any> = await flightBooking.getBooking(
        payload
      );
      if (response.success && response.status === StatusCodes.OK) {
        const flightDetailResponse = response.data?.data || {};
        const flightDetails = flightDetailResponse.flight_details;

        context.commit("saveBookingStatus", flightDetailResponse?.status);
        context.commit(
          "savePnrExpiry",
          flightDetailResponse?.confirmed_booking_expires_at
        );
        context.commit("saveSupplierPnr", flightDetails?.supplier_pnr);
        context.commit("saveFlightDetails", flightDetails);

        const travelerFormItems = [
          ...Array.from(Array(flightDetails.adult_count).keys()).map(() => ({
            ...BookingFormTravelerData,
            is_international: flightDetails?.is_international,
            passenger_type: TravelerTypes.ADULT,
            nationality: "PK",
          })),
          ...Array.from(Array(flightDetails.child_count).keys()).map(() => ({
            ...BookingFormTravelerData,
            is_international: flightDetails?.is_international,
            passenger_type: TravelerTypes.CHILD,
            nationality: "PK",
          })),
          ...Array.from(Array(flightDetails.infant_count).keys()).map(() => ({
            ...BookingFormTravelerData,
            is_international: flightDetails?.is_international,
            passenger_type: TravelerTypes.INFANT,
            nationality: "PK",
          })),
        ];

        context.commit("saveTravelers", travelerFormItems);
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
      } else {
        throw response;
      }
    } catch (err: any) {
      loggerService.logError(`${methodName}:`, err);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description = err?.error || "";
      notificationService.triggerNotification();
    } finally {
      context.commit("setLoading", false);
    }
  },

  // **** Confirm Flight Booking ****
  async confirmFlightBooking(
    context: ActionContext<IFlightBooking, IFlightBooking>,
    {
      payload,
      successCallback,
    }: {
      payload: IConfirmBooking;
      successCallback: (booking_id: string) => void;
    }
  ) {
    const methodName = "actions.confirmFlightBooking";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setConfirmBookingLoading", true);
      const flightBooking = new FlightBookingService();
      const response: IAGResponse<any> = await flightBooking.confirmBooking(
        payload
      );
      if (response.success && response.status === StatusCodes.OK) {
        const booking_id = response?.data?.data?.booking_id;
        successCallback(booking_id);
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
      } else {
        throw response;
      }
    } catch (err: any) {
      loggerService.logError(`${methodName}:`, err);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description = err?.error || "";
      notificationService.triggerNotification();
    } finally {
      context.commit("setConfirmBookingLoading", false);
    }
  },

  // **** Cancel Flight Booking ****
  async cancelFlightBooking(
    context: ActionContext<IFlightBooking, IFlightBooking>,
    {
      payload,
      successCallback,
    }: {
      payload: ICancelBookingPayloadType;
      successCallback: () => void;
    }
  ) {
    const methodName = "actions.cancelFlightBooking";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setCancelBookingLoading", true);
      const flightBooking = new FlightBookingService();
      const response: IAGResponse<any> = await flightBooking.cancelBooking(
        payload
      );
      if (response.success && response.status === StatusCodes.OK) {
        successCallback();
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
      } else {
        throw response;
      }
    } catch (err: any) {
      loggerService.logError(`${methodName}:`, err);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description = err?.message || "";
      notificationService.triggerNotification();
    } finally {
      context.commit("setCancelBookingLoading", false);
    }
  },

  // **** Cancel Flight Booking ****
  async downloadFlightBooking(
    context: ActionContext<IFlightBooking, IFlightBooking>,
    payload: GetDownloadBookingPayloadType
  ) {
    const methodName = "actions.downloadFlightBooking";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setDownloadTicketLoading", true);
      const flightBooking = new FlightBookingService();
      const response: IAGResponse<any> =
        await flightBooking.downloadFlightTicket(payload);
      if (response.success && response.status === StatusCodes.OK) {
        dowload(response.data, "ticket.pdf");
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
      } else {
        throw response;
      }
    } catch (err) {
      loggerService.logError(`${methodName}:`, err);
    } finally {
      context.commit("setDownloadTicketLoading", false);
    }
  },

  // **** Void Flight Booking ****
  async voidPIAFlightBooking(
    context: ActionContext<IFlightBooking, IFlightBooking>,
    {
      payload,
      successCallback,
    }: {
      payload: IVoidBookingPayloadType;
      successCallback: () => void;
    }
  ) {
    const methodName = "actions.voidPIAFlightBooking";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setVoidTicketLoading", true);
      const flightBooking = new FlightBookingService();
      const response: IAGResponse<any> = await flightBooking.voidPIABooking(
        payload
      );
      if (response.success && response.status === StatusCodes.OK) {
        successCallback();
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description = response.message?.toString() || "";
        notificationService.triggerNotification();
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
      } else {
        throw response;
      }
    } catch (err: any) {
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description = err?.error || "";
      notificationService.triggerNotification();
      loggerService.logError(`${methodName}:`, err);
    } finally {
      context.commit("setVoidTicketLoading", false);
    }
  },
};

export default actions;
