import { MutationTree } from "vuex";
import { IWebsiteConfig } from "@/ag-flight-components/interfaces/IWebsite.interface";
import { WebsiteConfig } from "@/ag-flight-components/types/WebsiteConfig";

const mutations: MutationTree<IWebsiteConfig> = {
  loadingWebsiteConfig(state, payload: boolean) {
    state.isLoading = payload;
  },
  setPageNotFound(state, payload: boolean) {
    state.pageNotFound = payload;
  },
  saveWebsiteConfig(state, payload: WebsiteConfig) {
    state.websiteConfig = payload;
  },
};

export default mutations;
